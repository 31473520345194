const CHAVE = '@assistamais'

export const gravarDados = (dados) => {
  localStorage.setItem(CHAVE, JSON.stringify(dados));
}

export const obterDados = () => JSON.parse(localStorage.getItem(CHAVE));

export const logout = () => localStorage.removeItem(CHAVE);

export const isLogado = () => {
  const dados = obterDados();

  if (dados && dados.tokens && dados.tokens.access) {
    return true
  }

  return false;

}

export const doLogin = () => {
  var url = window.location.href;
  if (url.indexOf('?') > -1) {
    url += '&dologin=true'
  } else {
    url += '?dologin=true'
  }
  window.location.href = url;
}