import React from 'react';
import './style.scss';


export default function ItemBanner({ img, children }) {

    return (
        <div className="item-banner">
            <div className="item-banner-background" style={{ backgroundImage: `url(${img})`, }}>

            </div>
            <div className="item-banner-conteudo">
                {children}
            </div>

        </div>
    );
}