import React, { useState } from 'react';
import FaqItem from '../../atoms/FaqItem';
import BuscaLocal from '../BuscaLocal';
import { faqJson } from './faqJson.js';
import './style.scss';


export default function Faq({ className = '', menuRef }) {

    const [valorPesquisa, setValorPesquisa] = useState('');

    const handlePesquisa = (filtro) => {

        setValorPesquisa(filtro);
    }

    return (
        <div className={`faq ${className}`}>
            <div className="faq-busca">
                <BuscaLocal placeholder="Digite sua dúvida" onSubmit={handlePesquisa} />
            </div>

            {faqJson.filter(f => f.pergunta.indexOf(valorPesquisa) !== -1).map((item) => (
                <FaqItem key={item.id} titulo={item.pergunta} resposta={item.resposta} menuRef={menuRef} />
            ))}
        </div>

    );
}