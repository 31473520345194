import React, { useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './style.scss';


export default function Card({ posterPath, name, providers, onClick, className = '', onMouseOver, onMouseLeave, linkDetalhe, isFav }) {
    //    const [isMouseOver, setIsMouseOver] = useState(false);
    //     const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    let timeout;
    const handleMouseOver = (e) => {
        if (onMouseOver) {
            timeout = setTimeout(() => {
                return onMouseOver(e, getClientRect())
            }, 400)

        }
    }
    const handleMouseLeave = () => {
        clearTimeout(timeout);
    };

    const cardRef = useRef(null);

    const getClientRect = () => {
        return cardRef.current.getBoundingClientRect();
    }

    return (
        <div className={`card-box ${className}`} ref={cardRef}>
            <div className={`colunas` }>
                <div className="card-body" style={{ height: undefined, }} onMouseOver={handleMouseOver} onClick={() => getClientRect()} onMouseLeave={handleMouseLeave}>
                    <LazyLoadImage src={`https://image.tmdb.org/t/p/w220_and_h330_face//${posterPath}`} alt={name} className={`card-image-poster ${isFav ? 'card-fav' : '' }`} />
                </div>
            </div>

            <div className={`mobile `}>
                <a href={linkDetalhe}>
                    <div className="card-body" style={{ height: undefined, }} >

                        <LazyLoadImage src={`https://image.tmdb.org/t/p/w220_and_h330_face//${posterPath}`} alt={name} className={`card-image-poster ${isFav ? 'card-fav' : '' }`} />

                    </div>
                </a>
            </div>

        </div>
    );
}