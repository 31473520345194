import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './style.scss';

export default function CardProvider({ logoPath, name, onClick, className }) {

    return (
        <div className={`card-box-provider ${className||''}`}>
            <div className="card-body" style={{ height: undefined, }} onClick={onClick}>
                <LazyLoadImage src={`https://image.tmdb.org/t/p/w500/${logoPath}`} alt={name} className="card-image-poster" />
            </div>
        </div>
    );
}