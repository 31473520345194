import { faAngleDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { MessageContext } from '../../../contexts/MessageContext';
import { SessionContext } from '../../../contexts/SessionContext';
import './style.scss';


export default function InputBusca({ placeholder, onSubmit }) {
    const [valor, setValor] = useState('');
    const [provider, setProvider] = useState(0);
    const [genrer, setGenrer] = useState(0);
    const [mostrarFiltro, setMostrarFiltro] = useState(false);
    const [, , , systemData] = useContext(SessionContext);
    const [showMessage, showError] = useContext(MessageContext);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = () => {
        if (valor.replaceAll(" ", '').length === 0) {
            showError("Digite um texto para realizar a busca");
            return
        }
        onSubmit(valor, { provider, genrer });
    }

    const handleTextChange = (e) => setValor(e.target.value);
    const handleProviderChange = (e) => setProvider(e.target.value);
    const handleGenrerChange = (e) => setGenrer(e.target.value);

    return (
        <div className="busca-container">

            <div className="busca" >
                <div className="input-busca">
                    <input type="text" name="busca" value={valor} placeholder={placeholder} onChange={handleTextChange} onKeyDown={handleKeyDown} />
                    <div className="botao lupa" onClick={handleSubmit}><FontAwesomeIcon icon={faSearch} /></div>
                    <div className="fundo-botao-filtro">
                        <div className="botao filtro" onClick={() => setMostrarFiltro(!mostrarFiltro)}>MAIS FILTROS <span style={{ width: 20 }} /> <FontAwesomeIcon icon={faAngleDown} size="2x" /></div>
                    </div>
                </div>
            </div>
            {mostrarFiltro &&
                <div className="filtro-busca">
                    <select onChange={handleProviderChange}>
                        <option value="0">Todas as plataformas</option>
                        {systemData.providers.map(p =>
                            <option key={`provider-option-${p.provider_id}`} value={p.provider_id}>{p.provider_name}</option>
                        )}
                    </select>

                    |
                    <select onChange={handleGenrerChange}>

                        <option value="0">Todos os gêneros</option>
                        {systemData.genres.map(g =>
                            <option key={`genrer-option-${g.id}`} value={g.id}>{g.name}</option>
                        )}


                    </select>
                </div>
            }
        </div>

    );
}