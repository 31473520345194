import React from 'react';
import BotaoArredondado from '../../atoms/BotaoArredondado';
import Popup from '../../molecules/Popup';
import './style.scss';

export const PopupAviso = ({ titulo, mensagem, buttomOk = false, onClose, ...rest }) => {

    return (
        <Popup onClose={onClose} {...rest}>
            <div style={{justifyContent: "center"}} className="popup-aviso">
                <div style={{fontWeight: "bold"}} className='popup-aviso-titulo'>{titulo}</div>
                <div style={{fontWeight: "bold"}} className='popup-aviso-mensagem'>{mensagem}</div>
                {buttomOk &&
                        <BotaoArredondado style={{marginTop: 10}} onClick={onClose} value={"Ok, entendi"} />

                }
            </div>
        </Popup>
    )
};

export default PopupAviso;