import ReactStars from "react-rating-stars-component";
import './style.scss';


const ReactStarsCustom = ({ onChange, value = 0, className = '' }) => {

    return (

        <ReactStars
            key="react-estrelinhas"
            count={5}
            onChange={onChange}
            size={40}
            value={value}
        />


    )
};

export default ReactStarsCustom;