import React from 'react';
import Anuncio from '../../atoms/Anuncio';
import Pontilhado from '../../atoms/Pontilhado';
import TituloVermelhoNeon from '../../atoms/TituloVermelhoNeon';
import CardPost from '../../molecules/CardPost';
import './style.scss';


const PostsRelacionados = ({ posts, anuncio }) => {

    return (
        <div className="posts-relacionados-body">
            <div className="card-post-divisor-cabecalho"></div>
            <TituloVermelhoNeon> POSTS RELACIONADOS</TituloVermelhoNeon>
            <div className="lista-post-relacionados">
                <CardPost className="card-post-container-custom" titulo="Lorem mum et dolor" descricao="Ipsum is simply Dummy text of the printing Typesetting industry..." img="" />
                <CardPost className="card-post-container-custom" titulo="Lorem mum et dolor" descricao="Ipsum is simply Dummy text of the printing Typesetting industry..." img="" />
                <CardPost className="card-post-container-custom" titulo="Lorem mum et dolor" descricao="Ipsum is simply Dummy text of the printing Typesetting industry..." img="" />
            </div>
            <Pontilhado className="card-post-pontilhado" />
            <div className="card-post-publicidade">
                <div className="card-post-publicidade-label">PUBLICIDADE</div>
                <Anuncio anuncio={anuncio} />
            </div>
        </div>
    )
};


export default PostsRelacionados;