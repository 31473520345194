import React, { useImperativeHandle, useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { isSafari, isIOS, isChrome } from 'react-device-detect';
import { anuncioView } from '../../../actions/assistamais/api';
import BotaoBordaArredondado from '../BotaoBordaArredondado';
import DialogPopup from '../DialogPopup';
import './style.scss';

import VolumeOffIcon from '@material-ui/icons/VolumeOff';

const VideoPopup = ({ className = '', url, controls = false, allowClose = false, onVideoEnded, poster = false, monetizado }, ref) => {
    const [showVideo, setShowVideo] = useState(false);
    const [urlVideo, setUrlVideo] = useState(undefined);
    const [anuncioToken, setAnuncioToken] = useState();
    const [anuncioLink, setAnuncioLink] = useState();
    const [anuncioId, setAnuncioId] = useState();
    const [duracao, setDuracao] = useState(0);
    const [progresso, setProgresso] = useState(0);
    const [videoIsOver, setVideoIsOver] = useState(false);
    const [videoInteracted, setVideoInteracted] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isPlayed, setIsPlayed] = useState(true);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 961px)' });

    const dialogRef = useRef();

    const handleVideoEnded = (e) => {
        setVideoIsOver(true);
        console.log({ anuncioId: anuncioId, token: anuncioToken })
        if (anuncioToken) {
            anuncioView({ anuncioId: anuncioId, token: anuncioToken }).then((res) => {
               
                let data = res.payload.data;
                console.log(anuncioId)
                onVideoEnded({...e, pontos: data.pontos});
                if (onVideoEnded) {
                }    
            });
        } else {
            onVideoEnded(e);
            if (onVideoEnded) {
            }
        }
        setTimeout(() => {
            setShowVideo(false);
        }, 1000);
    }

    useImperativeHandle(ref, () => ({
        show: () => setShowVideo(true),
        setUrl: (url) => setUrlVideo(url),
        setAnuncioLink: (link) => setAnuncioLink(link),
        setAnuncioToken: (token) => setAnuncioToken(token),
        setAnuncioId: (id) => setAnuncioId(id),
    }));

    const handleClickOutside = () => {
        if (allowClose) {
            setShowVideo(false);
        }
    }

    const handleClosePopup = () => {
        if(videoIsOver) {
            setShowVideo(false);
            if(progresso === 0){
                // if(anuncioLink){
                //     window.open(anuncioLink, "_blank")
                // }
            }
            
        } else if (showVideo) {
            dialogRef.current.show();
            setIsPlayed(false);
        }
    }

    const handleMuted = () => {
        setIsMuted(!isMuted);
    }

    const handleCloseConfirm = () => {
        setShowVideo(false);
    }

    const handleLinkClick = () => {
        window.open(anuncioLink, "_blank")
    }

    const handleVideoPlay = () => {
        console.warn('video play')
        
        // setTimeout(() => {
        //     setIsMuted(false)
        // }, 2000)
    }

    const handleVideoClick = () => {
        console.warn('video click')
        setVideoInteracted(true);
        setIsPlayed(true);
        // setTimeout(() => {
        //     setIsMuted(false)
        // }, 2000)
    }

    useEffect(() => {
        setIsMuted(true); 
    },[]);


    return (
        <>
            {showVideo && <div className={`video-popup ${className}`} onClick={handleClickOutside}>
                {
                    isIOS && isMuted && <BotaoBordaArredondado value="" className="video-popup-mute" onClick={handleMuted} >
                        <VolumeOffIcon />
                    </BotaoBordaArredondado>
                }
                {/* <div>Mudo: { isMuted + '' } | iOS: { isIOS + '' } | safari: {isSafari  + ''} </div> */}
                <ReactPlayer
                    muted={ isIOS && isMuted }
                    onEnded={handleVideoEnded}
                    onPlay={handleVideoPlay}
                    width={isTabletOrMobile ? '90%' : '60%'}
                    playsinline={true}
                    onProgress={(e) => setProgresso(Math.trunc(duracao - e.playedSeconds))}
                    onDuration={(e) => setDuracao(e)}
                    // onClick={handleMuted}
                    // onReady={handleVideoClick}
                    // onError={(err, data, el) => { el.getInternalPlayer().play() }}
                    playing={isPlayed}
                    url={url || urlVideo}
                                        
                    // previewTabIndex={2}
                    // config={{
                    //     file: {
                    //         forceHLS: true,
                    //     },
                    // }} 
                    >
                </ReactPlayer>
                    <div className='video-popup-rodape'>
                        {
                            anuncioId && progresso > 0 && 
                            < div className='video-popup-progresso'> Restam {progresso} segundos </div>
                        }
                        <BotaoBordaArredondado value="X" className="video-popup-close" onClick={handleClosePopup} />
                        {/* <span onClick={handleLinkClick} className='video-popup-anuncio-link'>Acesse o nosso site</span> */}
                    </div>
                
                {anuncioLink &&
                    <div className="video-popup-anuncio-link">
                    </div>
                }


                <DialogPopup handleContinueVideo={() => setIsPlayed(true)} ref={dialogRef} allowClose={true} title="Deseja fechar?" subtitle={anuncioToken && !videoIsOver  ? "Se fechar este vídeo antes do final você não receberá pontos." : 'Este vídeo não tem pontuação'} onConfirm={handleCloseConfirm} />
            </div>}
        </>
    );
}

export default React.forwardRef(VideoPopup);