import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './style.scss';


export default function InputBuscaLocal({ placeholder, onSubmit }) {
    const [valor, setValor] = useState('');
    // const [provider, setProvider] = useState(0);
    // const [genrer, setGenrer] = useState(0);
    // const [mostrarFiltro, setMostrarFiltro] = useState(false);
    // const [, , , systemData] = useContext(SessionContext);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = () => {
        onSubmit(valor);
    }

    const handleTextChange = (e) => setValor(e.target.value);
    // const handleProviderChange = (e) => setProvider(e.target.value);
    // const handleGenrerChange = (e) => setGenrer(e.target.value);

    return (
        <div className="busca-container-local">

            <div className="busca-local" >
                <div className="input-busca-local">
                    <input type="text" name="busca-local" value={valor} placeholder={placeholder} onChange={handleTextChange} onKeyDown={handleKeyDown} />
                    <div className="botao lupa" onClick={handleSubmit}><FontAwesomeIcon icon={faSearch} /></div>

                </div>
            </div>

        </div>

    );
}