import React from 'react';

import './style.scss';

import tmdb from '../../../assets/img/rating/tmdb.png';

export default function TMDBRating({ className='', votacao,...rest }) {

    return (
        <div className={`tmdb-rating ${className}`} {...rest}>
            <img src={tmdb} alt="tmdb" /><div className="tmdb-rating-votacao">{votacao}</div>
        </div>
    );
}