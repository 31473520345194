import queryString from 'query-string';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { obterClienteResumo, transparentLogin } from '../../actions/assistamais/api';
import TrocarSenha from '../../componentes/organisms/TrocarSenha';
import config from '../../config/default';
import { MessageContext } from '../../contexts/MessageContext';
import { gravarDados } from '../../utils/Session';
import Home from '../Home';
import './style.scss';

export default function LoginAutomatico() {

    const [showMessage, showError] = useContext(MessageContext);


    useEffect(() => {
        
        validar();
    }, [])

    const validar = async () => {
        const params = queryString.parse(window.location.search);
        
        const result = await transparentLogin(params.token);

        if (result.payload.status === 200) {
            gravarDados(result.payload.data);
            const urlFoto = `${config.baseURLAssistaMais}/clientes/perfil/foto/${result.payload.data.user.id}`;

            obterClienteResumo().then(result2 => {
                if (result2.payload.status === 200) {
                    const perfil = { ...result2.payload.data, foto: urlFoto }
                    const data = { ...result.payload.data, perfil: perfil }
                    gravarDados(data);
                }
                window.location.href ='/';
            })
        } else {

            showError(result.payload.data.message);
            setTimeout(() => {
                window.location.href ='/';
            },2000);
        }
    }

    //const teste = { "name": "Jovem", "extra": { "genero": "M", "nascimento": "1980-12-03T00:00:00.000Z", "celular": "21987621999", "endereco": { "localizacao": { "type": "Point", "coordinates": [] }, "logradouro": "Avenida", "bairro": "Realengo", "cidade": "Rio", "cep": "21760250", "uf": "RJ" } } }

    return (
        <div className="reset-senha">


        </div>
    );
}



