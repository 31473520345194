import React from 'react';
import './newStyle.scss';


export default function Noticia({  dataPostagem, tituloNoticia, resumoNoticia, onLeiaMaisClick, img, classe }) {

    return (
        <div className={`newNoticia ${classe}`}>
            <div className="new-noticia-data">{dataPostagem}</div>
            {/* <div className="noticia-imagem"><img src={img} alt="" /></div> */}
            <div className="new-noticia-titulo"><p>{tituloNoticia}</p></div>
            <div className="new-noticia-detalhe">
                <p>{resumoNoticia}</p>
                <div class="new-leia-mais" onClick={onLeiaMaisClick}>saiba mais</div>
            </div>
        </div>

    );
}