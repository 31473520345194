import React from 'react';
import './style.scss';


export default function Input({ className = '', name, error, label, cad = false, ...rest }) {

    return (
        <div className={`input ${className}`}>
            <input {...rest} />
            {error && error[name] ? <span className="input-error">{error[name]}</span> : label && cad ? <><span className="input-label-cad">{label[0]}</span><span className="input-label-cad">{label[1]}</span> <span className="input-label-cad">{label[2]}</span></>: <span className="input-label">{label}</span>}
        </div>
    );
}