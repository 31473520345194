const config = { // http://escritorio.perim.info:3005 https://api.assistamais.beta.perim.info
  baseURL: 'https://api.themoviedb.org',
  baseURLAssistaMais: process.env.NODE_ENV === "development" ? 'http://localhost:3005/v1' : process.env.REACT_APP_API_BASE_URL_ASSISTAMAIS,
  // baseURLAssistaMais: process.env.NODE_ENV === "development" ? 'https://api.assistamais.net.br/v1' : 'https://api.assistamais.net.br/v1',
  apiKey: '7701ed05b24a98856be428d2119bc1ff',
  imageURL: 'https://image.tmdb.org/t/p/w500',
  bigImageURL: 'https://image.tmdb.org/t/p/original',
  tempoTransicaoAnuncio: 5000,
  staticURL: 'https://static.assistamais.net.br/'
};

export default config;