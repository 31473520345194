import React from 'react';
import './style.scss';

//${config.bigImageURL}${backdropPath}
const ImagemFundoDetalhe = ({ img, className = '', opacity = true, children }) => {

    return (
        <div className={`backdrop ${className}`}>
            {children}
            <div className={`backdrop-image ${opacity ? 'backdrop-image-opacity' : ''}`}
                style={{
                    backgroundImage: `url(${img})`,
                    backgroundRepeat: 'no-repeat',
                }}>

                <div className="efeito-gradiente">
                </div>
                <div className="efeito-gradiente-direita-esquerda">
                </div>
            </div>

        </div>
    )
};

export default ImagemFundoDetalhe;