import moment from 'moment';
import queryString from 'query-string';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { obterAnuncios } from '../../actions/assistamais/api';
import { discoverMovie, getGenresMovieList } from '../../actions/tmdb';
import fundo from '../../assets/img/pexels-august-de-richelieu-4260642.png';
import ListaTituloPorGenero from '../../componentes/templates/ListaTitulosPorGenero';
import { SessionContext } from '../../contexts/SessionContext';
import { popularTipo } from '../../utils/titulos';
import './style.scss';

export default function TituloPorGenero({ location }) {

    const [populares, setPopulares] = useState([]);
    const [emAlta, setEmAlta] = useState([]);
    const [lancamentos, setLancamentos] = useState([]);
    const [titulo, setTitulo] = useState('');

    const cardPopup = useRef(null);
    const [anuncios, setAnuncios] = useState([]);

    const [listaGeneros, setListaGeneros] = useState([]);

    let history = useHistory();

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    const popularGeneros = (results, genres) => {

        for (let r of results) {

            r.genres = [];
            r.genre_ids && r.genre_ids.forEach(id => {
                r.genres.push(genres.find(g => g.id === id))
            });
        }
    }

    const popularLinkDetalhe = (results, mediaType) => {

        for (let r of results) {
            let pathname = '/filme';
            if (r.tipo.toLowerCase() === 'tv') {
                pathname = '/serie';
            }
            r.linkDetalhe = `${pathname}/?id=${r.id}&type=${r.tipo}`;
        }
    }


    const carregarAnuncios = () => {
        try {
            obterAnuncios('TITULOS-POPULARES').then(result => {
                setAnuncios(result.payload.data.results[0].linha);
            });
        } catch (err) {
            console.error(err);
        }
    }


    useEffect(() => {
        const params = queryString.parse(location.search);

        window.scrollTo(0, 0);
        carregarAnuncios();

        async function fetchData() {

            const { genres } = (await getGenresMovieList()).payload.data;

            const genero = genres.filter(g => g.id == params.id);
            if (genero && genero.length > 0) {
                setTitulo(genero[0].name);
            }

            discoverMovie(`&vote_count.gte=10000&vote_average.gte=7&with_genres=${params.id}|`).then(result => {

                let { results } = result.payload.data;

                popularGeneros(results, genres);
                popularLinkDetalhe(results, 'movie');
                // popularTipo(results, 'movie');
                setPopulares(results)
            });

            const dataEmAlta = moment().subtract(90, 'days').format('YYYY-MM-DD');
            discoverMovie(`&primary_release_date.gte=${dataEmAlta}&vote_count.gte=500&vote_average.gte=6&with_genres=${params.id}|`).then(result => {
                let { results } = result.payload.data;

                popularGeneros(results, genres);
                popularLinkDetalhe(results, 'movie');
                setEmAlta(results)
            });

            const dataLancamentos = moment().subtract(90, 'days').format('YYYY-MM-DD');
            discoverMovie(`&primary_release_date.gte=${dataLancamentos}&with_genres=${params.id}|`).then(result => {
                let { results } = result.payload.data;

                popularGeneros(results, genres);
                popularLinkDetalhe(results, 'movie');
                setLancamentos(results)
            });

            const resultadoGeneros = [];

            for (let i = 0; i < genres.length; i++) {

                const g = genres[i];
                const result = await discoverMovie(`&with_genres=${g.id},${params.id}&watch_region=BR&`);

                let { results } = result.payload.data;

                popularGeneros(results, genres);
                popularLinkDetalhe(results, 'movie');
                let genero = { id: g.id, name: g.name, filmes: results };

                resultadoGeneros.push(genero);


            }
            setListaGeneros(resultadoGeneros);

        }
        fetchData()

    }, []);

    const handleClick = (e, data) => {

        const { innerWidth: width, innerHeight: height } = window;

        const x = e.pageX > width / 2 ? e.pageX - 432 + 20 : e.pageX;
        const y = e.clientY > height / 2 ? e.pageY - 481 + 20 : e.pageY;
        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    const handleMouseOver = (e, data, { top, left, width: cardWidth, height: cardHeight }) => {
        const { scrollY, scrollX } = window;

        const width = document.body.clientWidth;
        const multiplicador = width > 1366 ? 1 : 0.71;

        const x = scrollX + left + cardWidth / 2 - (432 * multiplicador) / 2;
        const y = scrollY + top + cardHeight / 2 - (481 * multiplicador) / 2;
        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    const handleClickVerMais = (id) => {

        const params = queryString.parse(location.search);

        history.push({
            pathname: '/titulos-genero-genero/',
            search: `?genero=${params.id}&genero2=${id}`
        }, {
            some: new Date()
        });

    }


    return <ListaTituloPorGenero
        emAlta={emAlta}
        listaGeneros={listaGeneros}
        populares={populares}
        lancamentos={lancamentos}
        titulo={titulo}
        fundo={fundo}
        subtitulo="O melhor conteúdo em"
        subtituloDestaque={titulo}
        anuncios={anuncios}
        onCheckMonetizado={() => carregarAnuncios()}
        tipoAnuncio="quadrado"
        onClickVerMais={handleClickVerMais}
    />


    // return (
    //     <div className="lista-titulos-genero">
    //         <Menu simples={false} />


    //         <ImagemFundoDetalhe img={fundo} className="lista-titulos-genero-fundo" opacity={false}>
    //             <div className="titulo-pagina">
    //                 <div className="titulo-principal">{titulo}</div>
    //                 <div className="titulo-secundario">O melhor conteúdo em <span>{titulo}</span></div>
    //             </div>
    //         </ImagemFundoDetalhe>

    //         <ListaAnunciosHorizontal anuncios={anuncios} key="lista-anuncios" />

    //         <CardPopup ref={cardPopup} />


    //         <ListaFilmesHorizontal onMouseOver={handleMouseOver} filmes={lancamentos} titulo={"Lançamentos"} onClick={handleClick} />
    //         <ListaFilmesHorizontal onMouseOver={handleMouseOver} filmes={emAlta} titulo={"Em alta"} onClick={handleClick} />
    //         <ListaFilmesHorizontal onMouseOver={handleMouseOver} filmes={populares} titulo={"Populares"} onClick={handleClick} />

    //         {listaGeneros?.map(genero => {
    //             return (
    //                 <ListaFilmesHorizontal onMouseOver={handleMouseOver} key={`lista-filmes-genero-${genero.id}`} titulo={genero.name} filmes={genero.filmes} onClick={handleClick} />
    //             )
    //         })}

    //         <Rodape />
    //     </div>
    // );
}


TituloPorGenero.propTypes = {

};

