import './newStyle.scss';


export default function NewCard({ dataPostagem, tituloNoticia, resumoNoticia, onLeiaMaisClick, img }) {

    return (
        <div className="noticia">
            <div className="noticia-data">{dataPostagem}</div>
            <div className="noticia-imagem"><img src={img} alt="" /></div>
            <div className="noticia-titulo"><p>{tituloNoticia}</p></div>
            <div className="noticia-detalhe">
                <p>{resumoNoticia}</p>
                <div class="leia-mais" onClick={onLeiaMaisClick}>LEIA MAIS</div>
            </div>
        </div>
    )
}