import React from 'react';

import './style.css';

import logo from '../../../assets/img/assista-mais-logo-branco.png';

export default function Logo({ className, onClick, ...rest }) {
    return (
        <div className={`logo ${className} ${onClick?'logo-cursor-pointer':''}`} onClick={onClick} {...rest}><img src={logo} alt="logo" /></div>
    );
}