import { useContext } from 'react';
import { anuncioClick } from '../../../actions/assistamais/api';
import { SessionContext } from '../../../contexts/SessionContext';
import { doLogin } from '../../../utils/Session';
import BotaoBordaArredondado from '../../atoms/BotaoBordaArredondado';
import ItemBanner from '../../atoms/ItemBanner';
import './style.scss';

const Banner1 = ({ anuncio = {}, subtitulo, labelButton = '', onClickButton, videoRef }) => {

    const [session, setSession, logado] = useContext(SessionContext);

    const handleClick = () => {
        if (anuncio.isMonetizado && !logado) {
            doLogin();
            return;
        }
        
        if (anuncio.tipo === 'LINK_EXTERNO') {
            
            if (anuncio.isMonetizado) {
                anuncioClick({ anuncioId: anuncio.id, token: anuncio.token });
            }
            window.open(anuncio.url, "_blank");
        } else if (anuncio.tipo === 'LINK_INTERNO') {
            if (anuncio.isMonetizado) {
                anuncioClick({ anuncioId: anuncio.id, token: anuncio.token });
            }
            window.location.href = anuncio.url;
        }

        if (anuncio.tipo === 'VIDEO') {
            if (videoRef) {

                videoRef.current.setAnuncioToken(anuncio.token || undefined);
                videoRef.current.setAnuncioId(anuncio.id || undefined);
                videoRef.current.setUrl(anuncio.url);
                videoRef.current.show();
            }
        }
    }

    return (
        <>
            <ItemBanner img={anuncio.imagemCapa}>
                <div className="banner1-container" onClick={handleClick}>

                    {/* <div className="banner1-titulo">{anuncio.titulo}</div> */}
                    {/* <div className="banner1-subtitulo">{anuncio.texto}</div> */}

                    {anuncio.labelButton && <BotaoBordaArredondado className="banner1-botao" value={anuncio.labelButton} onClick={handleClick} />}
                </div>
            </ItemBanner>
        </>

    )
};

export default Banner1;