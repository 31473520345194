import React from 'react';
import livre from '../../../assets/img/classificacao/livre.png';
import c10 from '../../../assets/img/classificacao/10.png';
import c12 from '../../../assets/img/classificacao/12.png';
import c14 from '../../../assets/img/classificacao/14.png';
import c16 from '../../../assets/img/classificacao/16.png';
import c18 from '../../../assets/img/classificacao/18.png';

import './style.scss';

export default function Classificacao({codigo, className = ''}) {

    const codigoParaObjeto = () => {
        switch (codigo) {
            case 'L':
                return livre;
            case '10':
                return c10;
            case '12':
                return c12;
            case '14':
                return c14;
            case '16':
                return c16;
            case '18':
                return c18;
            default:
                return null;
        }

    }

    return codigo && codigo.length > 0 ? (
        <img src={codigoParaObjeto()} className={`classificacao ${className}`} alt=""/>
    ):<></>;
}