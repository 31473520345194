import { useForm } from "../../../hooks/useForm";
import { mask, onlyNumbers } from "../../../utils";
import BotaoSomenteTexto from "../../atoms/BotaoSomenteTexto";
import Input from "../../atoms/Input";

export default function Contatos({ disabled, continuarClick, onBlockedClick }) {
    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
        updateData,
        changeField,
    } = useForm({ // the hook we are going to create
        validations: { // all our validation rules go here
            email: {
                required: {
                    value: true,
                    message: 'Preencha o e-mail',
                },
                custom: {
                    isValid: (value) => {
                        const mail = /^[\+\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                        return value.length >= 5 && value.match(mail);
                    },
                    message: 'Preencha com um email válido',
                },
            },
            telefone: {
                required: {
                    value: true,
                    message: 'Preencha o telefone',
                },
                custom: {
                    isValid: (value) => {
                        return value && onlyNumbers(value).length === 11;
                    },
                    message: 'Preencha com um telefone válido',
                },
            },
        },
        onSubmit: () => {
            continuarClick(data)
        },
        initialValues: { // used to initialize the data
            email: '',
            telefone: ''
        },
    });



    const mascara = (e, field) => {

        let textoAjustado;
        const textoAtual = onlyNumbers(e.target.value);
        const tamanho = textoAtual.length;

        const mascaraTelefone = '(##) ####-####';
        const mascaraCelular = '(##) #####-####';

        if (tamanho >= 11) {
            textoAjustado = mask(textoAtual, mascaraCelular);
        } else if (tamanho > 0) {
            textoAjustado = mask(textoAtual, mascaraTelefone);
        }

        return changeField(field, textoAjustado);
    }

    const handlePrecadastro = () => {
        const { email = '', telefone = '' } = JSON.parse(localStorage.getItem('preCadastro'));
        if( email !== '' && data['email'] === '')
            updateData({ email, telefone });
    }
    
    return (

        <div className="dados-pessoais">
            {disabled && <div className="camuflar" onClick={onBlockedClick} />}

            <div className="titulo-bloco">Contatos</div>
            <form onSubmit={handleSubmit}>
                <div className="fazer-cadastro-inputs">
                    <div className="row">

                        <Input type="email" onFocus = {handlePrecadastro} className="w50" placeholder="" label="Digite um e-mail válido, para envio do gift do Programa Use Mais Pontos!*" value={data['email']} onChange={handleChange('email')} error={errors} name="email" />
                        <Input type="text" className="w50" placeholder="" label="Digite o número do seu telefone*" value={data['telefone']} onChange={handleChange('telefone')} onBlur={e => mascara(e, 'telefone')} error={errors} name="telefone" />
                        {/* <InputMaskCustom className="w50" mask="(99) 99999 9999" label="Digite um celular válido" maskChar=" " value={data['telefone']} onChange={handleChange('telefone')} name="telefone" error={errors} /> */}
                    </div>
                    <BotaoSomenteTexto className="botao" value="Continuar" type="submit" />
                </div>
            </form>
        </div>
    )
};

