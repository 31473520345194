export const faqJson = [
    {
        "id": "1",
        "pergunta": "1. O que é a Plataforma Assista Mais?",
        "resposta": "O Assista Mais é uma plataforma guia dos títulos de filmes e séries disponíveis nos principais streamings. Aqui você encontra o conteúdo disponível em cada um deles e tem detalhes de cada título para você avaliar a compatibilidade com os seus interesses. <br /> <br /> O Assista Mais também conta com parceiros que fornecem benefícios exclusivos, além disso você tem acesso ao programa Use Mais Pontos, no qual você acumula pontos ao interagir com as publicidades disponíveis na plataforma e pode trocar por cartões de presente."
    },
    {
        "id": "2",
        "pergunta": "2. Tem algum custo para utilizar o Assista Mais?",
        "resposta": "Não. A plataforma é totalmente gratuita. Basta se cadastrar e aproveitar!"
    },
    {
        "id": "3",
        "pergunta": "3. Como realizo meu cadastro?",
        "resposta": "Para se cadastrar, você deve clicar no botão “ENTRAR” no canto superior direito, então clicar em “CADASTRE-SE” ou “Entrar com sua conta Google”, seguindo o passo a passo do formulário é só concluir e validar seu cadastro e aproveitar todos os benefícios que o Assista Mais oferece."
    },
    {
        "id": "4",
        "pergunta": "4. Não consigo acessar minha conta, e agora?",
        "resposta": "Verifique o e-mail e senha de login, eles devem ser compatíveis com os preenchidos no seu cadastro. Se mesmo assim não conseguir acessar, clique em Esqueci minha senha <a href='?recuperarSenha=true' >aqui</a> e digite seu endereço de e-mail de cadastro. Você receberá uma mensagem por e-mail para redefinição de sua senha. Por favor, também verifique a sua caixa de spam."
    },
    {
        "id": "5",
        "pergunta": "5. Consigo utilizar as funcionalidades da Assista Mais no meu celular? ",
        "resposta": "Sim, a interface da Assista Mais está preparada para que seja utilizada nos navegadores do seu celular."
    },
    {
        "id": "6",
        "pergunta": "6. Como funciona o programa Use Mais Pontos? ",
        "resposta": "Ao se cadastrar no Assista Mais, você já está apto a participar do programa Use Mais Pontos. Ao interagir com a publicidade espalhada pela plataforma, você acumula pontos, os quais poderão ser utilizados no resgate de cartões de presente de lojas diversas e assinaturas de streaming. Para mais detalhes veja a <a href='/pdfs/politica-de-pontos-assista-mais.pdf' target='_blank'>Política de Pontos</a>."
    },
    {
        "id": "7",
        "pergunta": "7. Como faço o resgate dos meus pontos?",
        "resposta": "Acesse o seu perfil de conta, verifique a lista de cartões de presente disponível e se você acumulou pontos suficientes para efetuar o resgate. Selecione o cartão de presente de seu interesse e solicite o resgate. Pronto! Você receberá uma notificação em seu e-mail quando o código do cartão de presente estiver disponível."
    },
    {
        "id": "8",
        "pergunta": "8. Consigo assistir filmes na plataforma Assista Mais?",
        "resposta": "O Assista Mais te mostra quais as plataformas de streaming nas quais você pode assistir o título do seu interesse, te direcionando para a página da plataforma selecionada. Lá você deverá fazer seu login para ter acesso a obra ou fazer sua assinatura, caso ainda não tenha."
    },
    {
        "id": "9",
        "pergunta": "9. Como cancelar minha conta?",
        "resposta": "Acesse o seu perfil de conta e clique em Excluir conta."
    },
    {
        "id": "10",
        "pergunta": "10. Não encontrei o título que queria, o que faço?",
        "resposta": "Qualquer dúvida, problema ou sugestão contate-nos <a href='mailto:contato@assistamais.net.br'>aqui</a>."
    },
    {
        "id": "11",
        "pergunta": "11. O que eu faço caso não tenha recebido o e-mail de confirmação do cadastro?",
        "resposta": "Entrar em contato com a central de atendimento via <a href='mailto:contato@assistamais.net.br'>contato@assistamais.net.br</a>. "
    },
    {
        "id": "12",
        "pergunta": "12. O que eu faço se esquecer a senha do Assista Mais?",
        "resposta": "Você poderá alterar a senha diretamente na página de login clicando no “esqueci minha senha”, logo depois será encaminhado um e-mail para redefinição de uma nova senha."
    },
    {
        "id": "13",
        "pergunta": "13. O que fazer se eu não receber meu giftcard?",
        "resposta": "Entrar em contato com a central de atendimento via <a href='mailto:contato@assistamais.net.br'>contato@assistamais.net.br</a>. "
    },
    {
        "id": "14",
        "pergunta": "14. Como faço se eu possuir cadastro no Use Mais preciso me cadastrar no Assista Mais?",
        "resposta": "Ao acessar a plataforma Use Mais, você terá um pré-cadastro dentro da plataforma , sendo necessário apenas completar as informações solicitadas, criar uma senha e utilizar normalmente."
    },
    {
        "id": "15",
        "pergunta": "15. Quantos pontos posso acumular por dia?",
        "resposta": "A quantidade de pontos varia de acordo com a disponibilidade de anúncios pontuáveis na plataforma."
    },
];