import React from 'react';
import Anuncio from '../Anuncio';
import './style.scss';


export default function AnuncioTiraEmPe({ className = '', children, ...rest }) {

    return (
        <Anuncio className={`anuncio-tira-em-pe ${className}`} {...rest} />
    );
}