import React from 'react';
import './style.scss';


export default function InputSelect({ className = '', label, name, error, children, ...rest }) {

    return (
        <div className={`input-select ${className}`}>
            <select {...rest} >
                {children}
            </select>
            {error && error[name] ? <span className="input-error">{error[name]}</span> : label && <span className="input-label">{label}</span>}
        </div>
    );
}