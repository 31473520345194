import React from 'react';
import ReactPlayer from 'react-player';
import Popup from '../../molecules/Popup';
import './style.scss';

export const VideoPlayer = ({ className, ...rest }) => {

    return (
        <div className={className}>
            <ReactPlayer {...rest}/>
        </div>
    )
};

export default VideoPlayer;