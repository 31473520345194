import React from 'react';
import './style.scss';


export default function MolduraPopup({ className = '', children, onClose, type, ...rest }) {

    return (

        <div className={`moldura-popup ${className}`}>
            <div className={`container ${type === 'light' ? 'container-bg-light' : ''}`}>

                {children}

            </div>
        </div>
    );
}