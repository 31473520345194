import React from 'react';

import './style.scss';
import pontilhado from '../../../assets/img/pontilhado.png'

export default function Pontilhado({ className='',img, ...rest }) {

    return (
        <div className={`pontilhado ${className}`} {...rest}>
            <img src={pontilhado} alt='pontilhado' />
        </div>
    );
}