import { useContext, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useGoogleLogout } from 'react-google-login';
import { useHistory } from "react-router-dom";
import { SessionContext } from '../../../contexts/SessionContext';
import { logout } from '../../../utils/Session';
import './style.scss';
const MenuPerfilLogado = ({ perfil = {}, className = '' }) => {
    const [aberto, setAberto] = useState(false)

    const [session, setSession, logado] = useContext(SessionContext);

    const clientId = '175857597463-qsf2pu0o23camne76motu6r3rtf6m9sm.apps.googleusercontent.com';

    const refMenu = useDetectClickOutside({ onTriggered: handleCloseMenu });

    const { signOut, loaded } = useGoogleLogout({
        clientId,
      })

    let history = useHistory();

    const handleSairClick = () => {
        signOut()
        logout();
        window.location.href = '/';
    }

    function handleCloseMenu() {
        setAberto(false);
    }

    return (
        <div className="menu-perfil-logado" ref={refMenu}>
            <img src={session.perfil.foto} alt="" className={`avatar ${className}`} onClick={() => setAberto(!aberto)} />
            {aberto &&
                <div className="menu-aberto">
                    <div className="menu-aberto-item"> Olá, {perfil.nome}</div>
                    <a className="menu-aberto-item" hover="1" href="" onClick={() => history.push('/minha-conta')}> Conta</a>
                    <a className="menu-aberto-item" hover="1" href="" onClick={() => history.push('/meus-pontos')}>Meus Pontos</a>
                    <a className="menu-aberto-item" hover="1" href="" onClick={() => history.push('/favoritos')}> Lista de favoritos</a>
                    <a className="menu-aberto-item" hover="1" href="" onClick={() => history.push('/ajuda')}> Central de ajuda</a>
                    <div className="menu-aberto-item" hover="1" logout="logout" onClick={handleSairClick}> Sair</div>
                    {/* <GoogleLogout
                        clientId="175857597463-qsf2pu0o23camne76motu6r3rtf6m9sm.apps.googleusercontent.com"
                        buttonText="Logout"
                        onLogoutSuccess={onSucc}
                        onFailure={onFailure}
                    >
                    </GoogleLogout> */}
                </div>
            }
        </div>
    )
};

export default MenuPerfilLogado;