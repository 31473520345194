import React from 'react';

import './style.css';

export default function SinopseFilmeSerie({ children, className='' }) {

    return (
        <div className={`sinopse-filme-serie ${className}`}>
            <div className="Text-Style-7">{children}</div>
        </div>
    );
}