import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../../contexts/SessionContext';
import ColunaRodapeLinks from '../../molecules/ColunaRodapeLinks';
import ColunaRodapeLinksPerfil from '../../molecules/ColunaRodapeLinksPerfil';
import ColunaRodapeOutros from '../../molecules/ColunaRodapeOutros';
import ColunaRodapeOutrosPerfil from '../../molecules/ColunaRodapeOutrosPerfil';
import ColunaRodapePoliticas from '../../molecules/ColunaRodapePoliticas';
import AvisoCookie from '../AvisoCoockie';
import './style.scss';

const RodapeConta = () => {
    const descubra = [
        { id: 'descubra-3', texto: 'Como funciona', link: '/comofunciona' },
        { id: 'descubra-2', texto: 'Notícias', link: '/noticias' },
        { id: 'descubra-1', texto: 'Listas', link: '/listas' },
        { id: 'descubra-5', texto: 'Perguntas Frequentes', link: '/ajuda' },
        { id: 'descubra-4', texto: 'Anuncie', link: '/anuncie' },
    ];
    const [generos, setGeneros] = useState([]);
    const [plataformas, setPlataformas] = useState([]);

    const [, , , systemData] = useContext(SessionContext);

    // const gratis = [{ texto: 'Youtube free' }, { texto: 'Netmovies' }, { texto: 'Pluto TV' }];
    // const aluguelCompra = [{ texto: 'Apple TV' }, { texto: 'Now' }, { texto: 'Google Play' }, { texto: 'Youtube' }, { texto: 'Vivo Play' }];
    useEffect(() => {
        // getGenreMovieList().then (result => {
        //     setGeneros(result.payload.data.genres.map( g => ({texto: g.name, link:`/titulosPorGenero/?id=${g.id}`})));
        // });

        setGeneros(systemData.genres.map(g => ({ id: `genero-${g.id}`, texto: g.name, link: `/titulosPorGenero/?id=${g.id}` })));


        // getWatchProvidersMovie().then(result => {
        //     setPlataformas(result.payload.data.results.map(p => ({ texto: p.provider_name, link: `/titulosPorPlataforma/?id=${p.provider_id}&name=${p.provider_name}` })));
        // });
        setPlataformas(systemData.providers.map(p => ({ id: `provider-${p.provider_id}`, texto: p.provider_name, link: `/titulosPorPlataforma/?id=${p.provider_id}&name=${p.provider_name}` })));

    }, [systemData])

    return (
        <>
            {/* <AvisoCookie /> */}
            <div className="rodape hideInMobile">
                <div className='rodapeBgGray'>
                    <ColunaRodapeLinksPerfil titulo="DESCUBRA" itens={descubra} isMinhaConta={true} classNameTitulo="rodapeTitulo" />
                    <ColunaRodapeLinksPerfil titulo="FILMES E SÉRIES" itens={generos} itensGeneros={generos} itensPlataformas={plataformas} isMinhaConta={true} classNameTitulo="rodapeTitulo" />
                    {/* <ColunaRodapeLinksPerfil titulo="GAMES" subtitulo="" itens={plataformas} isMinhaConta={true} classNameTitulo="rodapeTitulo" /> */}
                    <ColunaRodapePoliticas />
                </div>

                <ColunaRodapeOutrosPerfil />
            </div>
            <div className='copyright hideInMobile'>
                <span>Copyright&copy;2022 Todos os direitos reservados - Versão 1.2.3 <br />USE MAIS : CNPJ 12.751.349/0001-44</span>
            </div>

            <div className='showInMobile rodapeContaMobile'>
                <div className="rodapeConta ">


                    <ColunaRodapeLinks titulo="GÊNEROS" itens={generos} classNameTitulo='linksColor' className='linksSpace' />


                    <ColunaRodapeLinks titulo="PLATAFORMAS" subtitulo="" itens={plataformas} classNameTitulo='linksColor' className='linksSpace'/>

                    <ColunaRodapeLinks titulo="DESCUBRA" itens={descubra} classNameTitulo='linksColor' className='linksSpace'/>

                    <ColunaRodapeOutros className='politicasColor' classNameImg='imgRodape' showText={true} classNameBody='bodyRodape'/>
                </div>
                <div className='copyright'>
                    <span>Copyright 2022 Todos os direitos reservados <br /> Versão 1.2.3 </span>
                    {/* <span>Copyright&copy;2022 Todos os direitos reservados - Versão 1.2.3 <br />USE MAIS : CNPJ 12.751.349/0001-44</span> */}
                </div>
            </div>
            

                
        </>
    )
};

export default RodapeConta;