import './style.scss';

export default function TituloVermelhoNeon({ children }) {

    return (
        <div className="titulo-vermelho-neon">
            <div className="titulo-vermelho-divisor">&nbsp;</div>
            <div className="titulo-vermelho-texto">
                {children}
            </div>
        </div>
    );
}