import React, { useImperativeHandle, useState } from 'react';
import BotaoArredondado from '../BotaoArredondado';
import BotaoBordaArredondado from '../BotaoBordaArredondado';
import BotaoSomenteTexto from '../BotaoSomenteTexto';
import './style.scss';


/*
tipo 1 => Texto verde , alinhado à esquerda
tipo 2 => Texto cinza , alinhado ao centro
*/
const DialogPopup = ({ className = '', title = "", subtitle = "", allowClose = false, confirmLabel = 'OK', onConfirm, onCancel, tipo = "1", handleContinueVideo, popupPontos = false }, ref) => {

    const [showDialog, setShowDialog] = useState(false);


    useImperativeHandle(ref, () => ({
        show: () => setShowDialog(true),
    }));

    const handleClickOutside = () => {
        if (allowClose) {
            //setShowDialog(false);
        }
    }

    const handleClickNao = () => {

        if (popupPontos) {
            setShowDialog(false);
            if (onCancel) {
                onCancel();
            }
        } else {
            if (onCancel) {
                onCancel();
            }
            setShowDialog(false);
            handleContinueVideo()
        }

        
    }

    const handleClickOk = () => {
        if (onConfirm) onConfirm();

        setShowDialog(false);
    }

    return (
        <>
            {showDialog &&
                <div className={`dialog-popup ${className}`} onClick={handleClickOutside}>
                    <div className="dialog-body">
                        <div className={`dialog-title dialog-title-tipo-${tipo}`}>{title}</div>
                        <div className={`dialog-subtitle dialog-subtitle-tipo-${tipo}`}>{subtitle}</div>
                        <div className={`dialog-buttons dialog-buttons-tipo-${tipo}`}>
                            {tipo === '1' &&
                                <>
                                    <BotaoBordaArredondado  value="NÃO" className="dialog-custom-button" onClick={handleClickNao} />
                                    <BotaoArredondado value={confirmLabel} type="button" className="dialog-custom-button ok-button" onClick={handleClickOk} />
                                </>
                            }
                            {tipo === '2' &&
                                <>
                                    <BotaoArredondado value="NÃO" className="dialog-custom-button" onClick={handleClickNao} />
                                    <BotaoSomenteTexto value={confirmLabel} type="button" className="dialog-custom-button ok-button" onClick={handleClickOk} />
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default React.forwardRef(DialogPopup);