import TMDBRating from '../../atoms/TMDBRating';
import './style.scss';

const RatingFilmeSerie = ({votacaoTMDB, className=''}) => {
    //obterClassificacao(filme.release_dates)
    return (
        <div className={`rating-filme-serie ${className}`} >
        
            <TMDBRating votacao={votacaoTMDB} />

        </div>
    )
};

export default RatingFilmeSerie;