import axios from 'axios';
import config from '../config/default';
import { isLogado, obterDados } from './Session';

const apiRequest = () => axios.create({ baseURL: config.baseURL, validateStatus: (status) => true });
export const apiAssistaMaisRequest = (headerAdicional = {}) => {

    const headers = isLogado() ? { Authorization: `Bearer ${obterDados().tokens.access.token}` } : {};

    const axiosInstance = axios.create({ baseURL: config.baseURLAssistaMais, headers: { ...headers, ...headerAdicional }, validateStatus: (status) => true });

    axiosInstance.interceptors.response.use(function (response) {
        console.warn('passei pelo axios', response.status)

        if(response.status === 403) {
            localStorage.removeItem('@assistamais');
            window.location.href = '/?dologin=true';
        }
        return response;
    }, function (error) {
        return Promise.reject(error);
    });
    return axiosInstance;
}

export default apiRequest;
