import React from 'react';

import './style.scss';

export default function LabelQuadrado({ children, className = ''}) {

    return (
        <div className={`label-quadrado ${className}`}>
            <div className="Text-Style-9">{children}</div>
        </div>
    );
}