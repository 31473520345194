import React, { useContext, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { obterOndeAssistir, obterOndeAssistirTV } from '../../../actions/assistamais/api';
import detalhe from '../../../assets/img/add-detalhe.png';
import arrowDown from '../../../assets/img/arrow-down-assista-em.png';
import Config from '../../../config/default';
import { SessionContext } from '../../../contexts/SessionContext';
import { doLogin } from '../../../utils/Session';
import CardProvider from '../CardProvider';
import Favoritar from '../Favoritar';
import './style.scss';


const CardPopupInfo = (props) => {

    const [scale, setScale] = useState('scale(0.5)');
    const [providers, setProviders] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const { title, linkDetalhe, backdropPath, overview, genres, id, filme, tipo } = props;

    const [, , logado] = useContext(SessionContext);

    useEffect(() => {
        setScale('scale(1)');
    }, [])

    useEffect(() => {

        const tipoAux = tipo || filme.tipo;
        const filmeId = id || filme.id;

        if (tipoAux && filmeId && (title || filme.name)) {

            if (tipoAux.toLowerCase() === 'movie') {
                obterOndeAssistir(filmeId, title).then(result => {


                    if (result.payload.data && result.payload.data.results) {
                        setProviders(result.payload.data.results.providers)
                    }
                })
            } else if (tipoAux.toLowerCase() === 'tv') {
                obterOndeAssistirTV(filmeId, title).then(result => {
                    if (result.payload.data && result.payload.data.results) {
                        setProviders(result.payload.data.results.providers)
                    }
                })
            }
        }
    }, [id, title, filme.id, filme.tipo, tipo, filme.name])
    const handleProviderClick = (url) => {
        if (logado) {
            window.open(url, "_blank");
        } else {
            doLogin();
        }
    }

    return (
        <div className="card-popup-info-box" style={{ transform: scale }}>
            <a href={linkDetalhe}><LazyLoadImage src={`${Config.imageURL}${backdropPath}`} alt={title} className="card-image-backdrop"/>

            </a>
            {providers && providers.length > 0 &&

                <div className="card-popup-info-onde-assitir">
                    <div className='card-popup-info-onde-assitir-menu'>
                        <div className='card-popup-info-onde-assitir-titulo'>Assista em</div>
                        <span className='card-popup-info-onde-assitir-provider-dropdown'>

                            {providers.length === 1 &&
                                <CardProvider className="card-popup-info-custom-provider" logoPath={providers[0].logo_path} onClick={() => handleProviderClick(providers[0].url)} />

                            }

                            {providers.length > 1 && <>
                                <CardProvider className="card-popup-info-custom-provider" logoPath={providers[0].logo_path} onClick={() => handleProviderClick(providers[0].url)} />
                                <span className='card-popup-info-onde-assitir-dropdown' onClick={() => setMenuOpen(!menuOpen)}>
                                    <img src={arrowDown} alt='' />
                                </span>
                            </>
                            }

                        </span>

                    </div>
                    {menuOpen &&

                        <div className='card-popup-info-onde-assitir-dropdown-options'>
                            {providers.map(p =>
                                <CardProvider className="card-popup-info-custom-provider" logoPath={p.logo_path} onClick={() => handleProviderClick(p.url)} />
                            )}
                        </div>
                    }
                </div>
            }
            <div className="titulo">{filme.title || filme.name}</div>
            <div className="body" style={{ height: undefined, }} >
                <div className='info-favoritar'>
                    <a href={linkDetalhe}><img className="card-popup-icone" src={detalhe} alt="Detalhe" /></a>
                    <Favoritar className="favoritar-custom" filme={filme} id={id} tipo={tipo} />
                    <div className="card-popup-tipo">
                        <span>{(filme.tipo.toLowerCase() === 'tv' ? 'Série' : (filme.tipo.toLowerCase() === 'movie' ? 'Filme' : ''))}</span>
                    </div>
                </div>
                <div className="card-popup-sinopse">{overview}</div>
                <div className="card-popup-generos">{genres.map(g => <div>{g?.name}</div>)}</div>
            </div>
        </div >
    )

}

export default CardPopupInfo;
//export default React.forwardRef(CardPopupInfo);