import React from 'react';
import './style.scss';

export const MessageType = { INFO: "info", ERROR: "error", WARN: "warn" }
export default function MensagemPadrao({ className = '', texto = "", tipo = 'INFO', ...rest }) {

    return (
        <div className={`mensagem-padrao mensagem-padrao-${tipo} ${className} `} {...rest}>
            {texto}
        </div>
    );
}