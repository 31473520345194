import React from 'react';

import './style.scss';

export default function ThumbWide({ className='', src, ...rest }) {

    return (
        <div 
            className={`thumb-wide ${className}`} {...rest} 
            style={{ backgroundImage: `url(${src})`,
            backgroundRepeat: 'no-repeat',}}>
            
        </div>
    );
}