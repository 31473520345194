import { useForm } from "../../../hooks/useForm";
import BotaoSomenteTexto from "../../atoms/BotaoSomenteTexto";
import Input from "../../atoms/Input";
import InputCheck from "../../atoms/InputCheck";

export default function Senha({ disabled, continuarClick, onBlockedClick }) {
    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
    } = useForm({ // the hook we are going to create
        validations: { // all our validation rules go here
            senha: {
                required: {
                    value: true,
                    message: 'Preencha a senha',
                },
                custom: {
                    isValid: (value) => {
                        
                        return value.length >= 6;
                    },
                    message: 'A senha precisa ter pelo menos 6 caracteres',
                },
            },
            senhaConfirmacao: {
                custom: {
                    isValid: (value) => value === data.senha,
                    message: 'As senhas devem ser iguais',
                },
                required: {
                    value: true,
                    message: 'Confirme a senha',
                },
            },
            termo: {
                custom: {
                    isValid: (value) => {
                        return value
                    },
                    message: 'É preciso aceitar os termos de uso para prosseguir.',
                },
            },
        },
        onSubmit: () => {
            continuarClick(data)
        },
        initialValues: { // used to initialize the data
            senha: '',
            autorizaReceberEmail: true,
        },
    });

    return (

        <div className="dados-pessoais">
            {disabled && <div className="camuflar" onClick={onBlockedClick} />}
            <div className="titulo-bloco">Senha</div>
            <form onSubmit={handleSubmit}>
                <div className="fazer-cadastro-inputs">
                    <div className="row">
                        <Input type="password" className="w50" placeholder="" label={[ 'Digite sua senha *', '- Mínimo 6 caracteres;', '- Aceita letras, números e caracteres especiais' ]} value={data['senha']} onChange={handleChange('senha')} error={errors} cad={true} name="senha" />
                        <Input type="password" className="w50" placeholder="" label="Repita sua senha *" value={data['senhaConfirmacao']} onChange={handleChange('senhaConfirmacao')} error={errors} name="senhaConfirmacao" />
                    </div>


                    <InputCheck name="termo" onChange={handleChange('termo')} value={data['termo']} error={errors} >
                        <div className="termos"> Li e concordo com os <a href="/pdfs/termo-de-uso-assista-mais.pdf" target="_blank" className="destaque">Termos de Uso e Políticas de Privacidade.</a></div>
                    </InputCheck>

                    <InputCheck name="autorizaReceberEmail" onChange={handleChange('autorizaReceberEmail')} checked={data['autorizaReceberEmail']} error={errors} >
                        <div className="termos"> Desejo receber conteúdo informativo e promoções por email</div>
                    </InputCheck>


                    <BotaoSomenteTexto className="botao" value="Continuar" type="submit" />

                </div>
            </form>
        </div>
    )
};

