import React, { createContext, useState } from 'react';
import MensagemPadrao, { MessageType } from '../componentes/atoms/MensagemPadrao';
export const MessageContext = createContext();

export const MessageApiProvider = (props) => {

    const [message, setMessage] = useState();
    const [show, setShow] = useState(false);
    const [tipo, setTipo] = useState('');
    let timeout;
    const showMessage = (text) => {
        setMessage(text)
        setTipo(MessageType.INFO);
        setShow(true);
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            closeMessage();
        }, 5000)
    }

    const showError = (text) => {
        setMessage(text);
        setTipo(MessageType.ERROR);
        setShow(true);
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            closeMessage();
        }, 5000)
    }

    const showMessageFromResult = (result, successText, errorText, cb) => {
        return result.then(resultado => {
            //showError(result.payload.data.message)
            const { status, data } = resultado.payload
            if (Math.trunc(status / 100) === 2) {
                if (successText) {
                    showMessage(successText);
                }
                if (cb) {
                    cb(data, true);
                }
                return true;
            } else {
                const error = data && data.message ? data.message : 'Algo inexperado aconteceu, tente novamente.';
                showError(errorText || error);
                if (cb) {
                    cb(data, false);
                }
                return false;
            }

        })
    }

    const closeMessage = () => {
        setShow(false);
    }

    return (
        <MessageContext.Provider value={[showMessage, showError, showMessageFromResult]}>
            {show && <MensagemPadrao texto={message} tipo={tipo} onClick={() => closeMessage()} />}
            {props.children}
        </MessageContext.Provider>
    );
}