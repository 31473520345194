import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { obterAnuncios } from '../../actions/assistamais/api';
import { discoverMovie, getGenresMovieList } from '../../actions/tmdb';
import fundo from '../../assets/img/pexels-august-de-richelieu-4260642.png';
import ListaTitulosGrid from '../../componentes/templates/ListaTitulosGrid';
import ListaTitulosGridRotativo from '../../componentes/templates/ListaTitulosGridRotativo';
import { SessionContext } from '../../contexts/SessionContext';
import { popularTipo } from '../../utils/titulos';
import './style.scss';

export default function ListaTitulosPopulares() {

    const [populares, setPopulares] = useState([]);
    const [providers, setProviders] = useState([]);
    const [lancamentos, setLancamentos] = useState([]);
    const [emAlta, setEmAlta] = useState([]);

    const [listaGeneros, setListaGeneros] = useState([]);
    const [nomePlataforma, setNomePlataforma] = useState([]);
    const [anuncios, setAnuncios] = useState([]);

    const cardPopup = useRef(null);
    let history = useHistory();

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    const popularGeneros = (results, genres) => {

        for (let r of results) {

            r.genres = [];
            r.genre_ids && r.genre_ids.forEach(id => {
                r.genres.push(genres.find(g => g.id === id))
            });
        }
    }

    const popularLinkDetalhe = (results, mediaType) => {

        for (let r of results) {
            let pathname = '/filme';
            if (r.tipo.toLowerCase() === 'tv') {
                pathname = 'serie';
            }
            r.linkDetalhe = `${pathname}/?id=${r.id}&type=${r.tipo}`;
        }
    }

    const carregarAnuncios = () => {
        try {
            obterAnuncios('TITULOS-POPULARES').then(result => {
                setAnuncios(result.payload.data.results[0].linha);
            });

            
        } catch (err) {
            console.error(err);
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        carregarAnuncios();
        async function fetchData() {

            //Buscar os filmes populares
            // getPopularAction().then(result => {
            //     // TODO criar função para usar em todos
            //     let { results } = result.payload.data;
            //     //popularGeneros(results, genres);
            //     popularLinkDetalhe(results, 'movie');
            //     setPopulares(results)
            //     console.log("getPopularAction" + results.length);
            // });


            //const params = queryString.parse(location.search);

            setNomePlataforma("Populares");


            const { genres } = (await getGenresMovieList()).payload.data;

            // discoverMovie(`&vote_count.gte=10000&vote_average.gte=7&watch_region=BR`).then(result => {
            //     let { results } = result.payload.data;

            //     popularGeneros(results, genres);
            //     popularLinkDetalhe(results, 'movie');
            //     setPopulares(results)
            // });

            // Buscando os dados da pagina 1 de populares
            let resultado = [];

            discoverMovie(`&vote_count.gte=1000&vote_average.gte=7&watch_region=BR`).then(result => {
                let { results } = result.payload.data;
                resultado = results

                // Buscando os dados da pagina 2 de populares
                // e juntando com os dados da pagina 1
                discoverMovie(`&vote_count.gte=1000&vote_average.gte=7&watch_region=BR`, 2).then(result => {
                    let { results } = result.payload.data;
                    for (let r of results) {
                        resultado.push(r);
                    }

                    popularGeneros(resultado, genres);
                    popularLinkDetalhe(resultado, 'movie');
                    setPopulares(resultado)

                });
            });

        }
        fetchData()

    }, []);


    return <ListaTitulosGridRotativo
        titulos={populares}
        tituloPagina="Populares"
        fundo={fundo}
        subtituloPagina=""
        anuncios={anuncios}
        onCheckMonetizado={() => carregarAnuncios()}
    />
}


ListaTitulosPopulares.propTypes = {

};

