import React from 'react';
import Carousel from "react-multi-carousel";
import CustomDot from '../../atoms/CustomDot';
import './style.scss';


const BannerFundo = ({ children, videoRef }) => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
            slidesToSlide: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const childrenWithProps = React.Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { videoRef: videoRef });
        }
        return child;
    });

    return (
        <div className="banner-fundo">

            <Carousel responsive={responsive}
                swipeable={false}
                draggable={false}
                infinite={true}
                showDots={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                arrows={false}
                customDot={<CustomDot />}
                partialVisible={false}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                removeArrowOnDeviceType={["tablet", "mobile"]}
            //deviceType={this.props.deviceType||''}
            >
                {childrenWithProps}

            </Carousel>
            <div className="efeito-gradiente">

            </div>

        </div>
    )
};

export default BannerFundo;