import React, { useContext, useImperativeHandle, useState } from 'react';
import { resetarSenha, trocarSenha } from '../../../actions/assistamais/api';
import { MessageContext } from '../../../contexts/MessageContext';
import { useForm } from '../../../hooks/useForm';
import { gravarDados, obterDados } from '../../../utils/Session';
import BotaoRetanguloArredondado from '../../atoms/BotaoRetanguloArredondado';
import Input from '../../atoms/Input';
import Popup from '../../molecules/Popup';
import './style.scss';

export const TrocarSenha = ({ resetSenha = false, token }, ref) => {

    const [show, setShow] = useState(false);

    const [showMessage, showError, showMessageFromResult] = useContext(MessageContext);


    useImperativeHandle(ref, () => ({
        open: () => setShow(true),
        close: () => setShow(false),
        toggle: () => {
            return setShow(!show);
        }
    }));

    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
        changeData,
        updateData
    } = useForm({ // the hook we are going to create
        validations: { // all our validation rules go here
            senhaAtual: {
                required: {
                    value: !resetSenha,
                    message: 'Digite a senha atual',
                },
            },
            senha: {
                required: {
                    value: true,
                    message: 'Preencha a senha',
                },
                custom: {
                    isValid: (value) => {
                        // const letter = /[a-fA-F]/;
                        // const number = /[0-9]/;
                        return value.length >= 6;
                    },
                    message: 'A senha precisa ter pelo menos 6 caracteres.',
                },
            },
            senhaConfirmacao: {
                required: {
                    value: true,
                    message: 'Confirme a senha',
                },
                custom: {
                    isValid: (value) => value === data.senha,
                    message: 'A confirmação de senha precisa ser igual a nova senha',
                },
            },
        },
        onSubmit: async () => {
            const _onChangePassword = (data, success) => {
                if (success) {
                    const sessao = obterDados();
                    sessao.token = data;
                    gravarDados(sessao);
                }
            }
            if (resetSenha) {
                if (await showMessageFromResult(resetarSenha(data.senha, token), "Senha alterada com sucesso")) {
                    setTimeout(() => window.location.href = '/?dologin=true', 3000);
                }
            } else {

                if (await showMessageFromResult(trocarSenha(data.senhaAtual, data.senha), "Senha alterada com sucesso", null, _onChangePassword)) {
                    setShow(false);
                }
            }

        },
        initialValues: { // used to initialize the data
            senha: '',
            senhaAtual: '',
            senhaConfirmacao: ''
        },
    });


    return show && (
        <Popup titulo="Alterar senha" subtitulo="" onClose={(e) => setShow(false)} className="popup-custom">
            <div className="trocar-senha-container">
                <form onSubmit={handleSubmit}>
                    <div className="trocar-senha-inputs">
                        {!resetSenha &&
                            <Input type="password" className="troca-senha-input" placeholder="" label="Digite a senha atual" onChange={handleChange('senhaAtual')} error={errors} name="senhaAtual" />
                        }

                        <Input type="password" className="troca-senha-input" placeholder="" label="Digite sua nova senha" onChange={handleChange('senha')} error={errors} name="senha" />

                        <Input type="password" className="troca-senha-input" placeholder="" label="Repita sua nova senha" onChange={handleChange('senhaConfirmacao')} error={errors} name="senhaConfirmacao" />
                    </div>
                    <div className="trocar-senha-acoes">
                        <BotaoRetanguloArredondado value="Salvar" className="botao-arredondado-hack" type="submit" />
                    </div>
                </form>
            </div>
        </Popup>
    )
};

export default React.forwardRef(TrocarSenha);