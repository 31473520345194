import { useContext } from 'react';
import { MessageContext } from '../../../contexts/MessageContext';
import { SessionContext } from '../../../contexts/SessionContext';
import { doLogin } from '../../../utils/Session';
import CardProvider from '../../atoms/CardProvider';
import './style.scss';

const ProviderGroup = ({ providers = [], className = '', titulo = '', ...rest }) => {

    const [, , logado] = useContext(SessionContext);
    const [, showError] = useContext(MessageContext);

    const handleProviderClick = (p) => {

        if (logado) {
            window.open(p.url, "_blank");
        } else {
            doLogin();
        }
    }

    return providers && providers.length > 0 && (
        <div className={`provider-group ${className}`}  {...rest}>
            <div className="provider-group-titulo">{titulo}</div>

            <div className="provider-group-cards">
                {providers.map(p =>
                    <CardProvider logoPath={p.logo_path} onClick={() => handleProviderClick(p)} />
                )}
            </div>
        </div>
    );
}

export default ProviderGroup;