import { useState } from 'react';
import './style.scss';

const ColunaRodapeLinks = ({ titulo, subtitulo = '', itens = [], link, classNameTitulo='',className='' }) => {

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleClick = () => {
        setMobileOpen(!mobileOpen);
    }

    return (
        <div className={`coluna-rodape-links ${className}`}>
            <div className={`titulo ${classNameTitulo}`} onClick={handleClick}>{titulo}</div>
            <div className="subtitulo items hideInMobile">
                <div >{subtitulo} </div>
            </div>

            <div className={`items ${mobileOpen ? '' : 'hideInMobile'}`} >
                {itens.map(i => (
                    <div key={`rodape-${i.id}`}><a href={i.link}>{i.texto}</a></div>
                ))}
            </div>

        </div >
    )
};

export default ColunaRodapeLinks;