import React from 'react';
import setaVoltarImg from '../../../assets/img/voltar-ico.png';
import './style.scss';


export default function SetaVoltar({ className = '', onClick, value, ...rest }) {

    return (
        <div className={`seta-voltar ${className}`} onClick={onClick}>
            <img src={setaVoltarImg} alt="voltar" />
        </div>
    );
}