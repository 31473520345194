import React, { useContext, useImperativeHandle, useState } from 'react';
import { recuperarSenha } from '../../../actions/assistamais/api';
import { MessageContext } from '../../../contexts/MessageContext';
import { useForm } from '../../../hooks/useForm';
import BotaoRetanguloArredondado from '../../atoms/BotaoRetanguloArredondado';
import Input from '../../atoms/Input';
import Popup from '../../molecules/Popup';
import './style.scss';




export const RecuperarSenha = ({ fazerCadastroClick }, ref) => {

    const [show, setShow] = useState(false);

    useImperativeHandle(ref, () => ({
        open: () => setShow(true),
        close: () => setShow(false),
        toggle: () => {
            return setShow(!show);
        }
    }));

    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
    } = useForm({ // the hook we are going to create
        validations: { // all our validation rules go here
            email: {
                required: {
                    value: true,
                    message: 'Preencha o e-mail',
                },
            },
        },
        onSubmit: () => {
            showMessageFromResult(recuperarSenha(data.email), "Enviamos um e-mail para recuperação da sua senha")
        },
        initialValues: { // used to initialize the data
            endereco: '',
            numero: '',
            bairro: '',
            complemento: '',
            cep: '',
            cidade: '',
            estado: '',
        },


    });

    const handleFazerCadastroClick = () => {
        setShow(false);
        fazerCadastroClick();
    }


    const [showMessage, showError, showMessageFromResult] = useContext(MessageContext);

    return show && (
        <Popup titulo="Recuperar senha" subtitulo="Você receberá um e-mail para redefinir sua senha" onClose={(e) => setShow(false)}>
            <form onSubmit={handleSubmit}>
                <div className="recuperar-senha-container">
                    <div className="recuperar-senha-inputs">
                        <Input type="text" className="fazer-login-input" placeholder="e-mail" onChange={handleChange('email')} error={errors} name="email" />
                    </div>
                    <div className="recuperar-senha-acoes">
                        <BotaoRetanguloArredondado value="Recuperar" className="botao-arredondado-hack" type="submit" />

                        <div className="nao-tem-cadastro">Não tem cadastro</div>
                        <a className="link-popup" href="#123" onClick={handleFazerCadastroClick}>Cadastre-se</a>
                    </div>
                </div>
            </form>
        </Popup>
    )
};

export default React.forwardRef(RecuperarSenha);