import React from 'react';
import './style.scss';


export default function ThumbQuadrada({ className = '', src, ...rest }) {

    return (
        <div
            className={`thumb-quadrada ${className}`} {...rest}
            style={{
                backgroundImage: `url(${src})`,
                backgroundRepeat: 'no-repeat',
            }}>

        </div>
    );
}