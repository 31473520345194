import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { obterAnuncios, obterNoticias } from '../../actions/assistamais/api';
import Banner1 from '../../componentes/molecules/Banner1';
import BannerFundo from '../../componentes/organisms/BannerFundo';
import ListaAnunciosHorizontal from '../../componentes/organisms/ListaAnunciosHorizontal';
import ListaNoticiasVertical from '../../componentes/organisms/ListaNoticiasVertical';
import Menu from '../../componentes/organisms/Menu';
import Rodape from '../../componentes/organisms/Rodape';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import { SessionContext } from '../../contexts/SessionContext';
import './style.scss';



export default function Blog({ videoRef }) {

    const [anuncios, setAnuncios] = useState([]);
    const [carrossel, setCarrossel] = useState([]);
    const [noticias, setNoticias] = useState([]);

    const cardPopup = useRef(null);
    let history = useHistory();

    const dataLinha = [{ id: 1, name: 'Lorem Ispum dolor et mum essetis nim', descricao: 'E ainda com recomendações, notícias, detalhes e informações diretas para você não perder tempo ou ficar na dúvida do que ver, sem ad fasiod fasidfu aisudfyasiodfy dasifyuadfsioyu..' },
    { id: 2, name: 'Lorem Ispum dolor et mum essetis nim', descricao: 'E ainda com recomendações, notícias, detalhes e informações diretas para você não perder tempo ou ficar na dúvida do que ver, sem ad fasiod fasidfu aisudfyasiodfy dasifyuadfsioyu..' },
    { id: 3, name: 'Lorem Ispum dolor et mum essetis nim', descricao: 'E ainda com recomendações, notícias, detalhes e informações diretas para você não perder tempo ou ficar na dúvida do que ver, sem ad fasiod fasidfu aisudfyasiodfy dasifyuadfsioyu..' }]

    const carregarAnuncios = () => {
        try {
            obterAnuncios('NOTICIAS').then(result => {

                setAnuncios(result.payload.data.results[0].linha);
                setCarrossel(result.payload.data.results[0].carrossel);
            });
        } catch (err) {
        }
    }

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    useEffect(() => {
        carregarAnuncios();
        try {

            obterNoticias().then(result => {

                setNoticias(result.payload.data.results.slice(0, 8).map(n => {
                    return { dataPostagem: '', name: n.title, descricao: n.contentSnippet, capa: n.capa, id: n.link }
                }));
            });
        } catch (err) {
            console.error(err);
        }
    }, [])

    const handleClickVerMais = (link) => {

        window.open(link, "_blank ")


    }

    return (
        <div>
            <Menu simples={false} >
                <BannerFundo videoRef={videoRef}>
                    {carrossel.map(c =>
                        <Banner1
                            key={`anuncio-${c.id}`}
                            anuncio={c}
                            labelButton="CONFIRA"
                        />
                    )}

                </BannerFundo>
            </Menu>

            <ListaAnunciosHorizontal anuncios={anuncios} onCheckMonetizado={() => carregarAnuncios()} />

            <div className='lista-noticias'>

                <div className='title-noticias'>
                    <div className='title-notice'>
                        <p>Notícias</p>
                    </div>
                    <div className="description-notice">
                        <p>Filmes e Séries</p>
                        <a target="_blank" href="https://cinepop.com.br/category/noticias-101/">ver todos</a>
                    </div>
                </div>

                <div className='new-lista-components'>
                    <ListaNoticiasVertical classe='red' onClick={handleClickVerMais} items={noticias.slice(0, 4)} />

                    <ListaNoticiasVertical classe='blue' onClick={handleClickVerMais} items={noticias.slice(4, 8)} />
                </div>
            </div>
            <RodapeConta />
        </div>
    );
}


Blog.propTypes = {

};

