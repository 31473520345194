import InputBusca from '../../atoms/InputBusca';
import './style.css';

const Busca = ({placeholder, onSubmit}) => {

    return (
        <div>
            <InputBusca placeholder={placeholder} onSubmit={onSubmit}/>
        </div>
    )
};

export default Busca;