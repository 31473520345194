import queryString from 'query-string';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { obterAnuncios, obterOndeAssistir } from '../../actions/assistamais/api';
import { getMovieCredits, getMovieDetails, getMovieImages, getMovieSimilar } from '../../actions/tmdb';
import VideoPopup from '../../componentes/atoms/VideoPopup';
import CamadaRelacionados from '../../componentes/organisms/CamadaRelacionados';
import ClassificarCompartilhar from '../../componentes/organisms/ClassificarCompartilhar';
import DadosGerais from '../../componentes/organisms/DadosGerais';
import GaleriaImagens from '../../componentes/organisms/GaleriaImagens';
import ImagemFundoDetalhe from '../../componentes/organisms/ImagemFundoDetalhe';
import ListaAnunciosHorizontal from '../../componentes/organisms/ListaAnunciosHorizontal';
import Menu from '../../componentes/organisms/Menu';
import OndeAssistir from '../../componentes/organisms/OndeAssistir';
import Rodape from '../../componentes/organisms/Rodape';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import SobreFilmeSerie from '../../componentes/organisms/SobreFilmeSerie';
import config from '../../config/default';
import { SessionContext } from '../../contexts/SessionContext';
import { popularTipo } from '../../utils/titulos';
import './style.scss';


export default function DetalheDoFilme({ location }) {

    const [filme, setFilme] = useState(undefined);
    const [staff, setStaff] = useState();
    const [movieImages, setMovieImages] = useState();
    const [backdrop, setBackdrop] = useState('');
    const [poster, setPoster] = useState('');
    const [similar, setSimilar] = useState([]);
    const [providers, setProviders] = useState([]);
    const [anuncios, setAnuncios] = useState([]);
    const [trailer, setTrailer] = useState('');

    const menuRef = useRef();
    const videoPopupRef = useRef();

    let history = useHistory();

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    const handleRelacionadosClick = (item) => {
        history.push({
            pathname: '/filme',
            search: `?id=${item.id}`
        }, {
            some: new Date()
        });
        window.scrollTo(0, 0);
    }


    const carregarAnuncios = () => {
        try {
            obterAnuncios('DETALHE-TITULO').then(result => {
                setAnuncios(result.payload.data.results[0].linha);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {

        const params = queryString.parse(location.search);

        setProviders([]);

        carregarAnuncios();

        window.scrollTo(0, 0);

        getMovieDetails(params.id).then(result => {

            if (result && result.payload && result.payload.data) {

                setFilme(result.payload.data);
                try {
                    const key = result.payload.data.videos.results.filter(v => v.type === 'Trailer')[0].key;
                    setTrailer(`https://www.youtube.com/watch?v=${key}`);
                } catch {
                    setTrailer(null);
                }

                obterOndeAssistir(params.id, result.payload.data.title).then(providers => {
                    if (providers.payload.data.results) {
                        setProviders(providers.payload.data.results.providers || []);
                    }
                });


            }
        });



        // getMovieWatchProviders(params.id).then(providers => {

        //     //getMovieWatchProviders(r.id).then(providers => {
        //     if (providers.payload.data.results && providers.payload.data.results.BR) {

        //         const novosProvedores = [];
        //         providers.payload.data.results.BR.flatrate?.map(fr => {
        //             const np = novosProvedores.find(np => fr.provider_id === np.provider_id)
        //             if (!np) {
        //                 novosProvedores.push({ ...fr, type: ['Assinatura'] })
        //             } else {
        //                 np.type.push('Assinatura')
        //             }
        //             return np;
        //         });
        //         providers.payload.data.results.BR.buy?.map(b => {
        //             const np = novosProvedores.find(np => b.provider_id === np.provider_id)
        //             if (!np) {
        //                 novosProvedores.push({ ...b, type: ['Compra'] })
        //             } else {
        //                 np.type.push('Compra')
        //             }
        //             return np;
        //         });

        //         providers.payload.data.results.BR.rent?.map(r => {
        //             const np = novosProvedores.find(np => r.provider_id === np.provider_id)
        //             if (!np) {
        //                 novosProvedores.push({ ...r, type: ['Aluguel'] })
        //             } else {
        //                 np.type.push('Aluguel')
        //             }
        //             return np;
        //         });
        //         setProviders(novosProvedores);
        //     }
        // });


        getMovieCredits(params.id).then(result => {
            if (result && result.payload && result.payload.data) {
                const { cast = [], crew = [] } = result.payload.data;

                const diretor = crew.filter(c => c.job === 'Director');
                const roteiro = crew.filter(c => c.job === 'Screenplay');
                const editor = crew.filter(c => c.known_for_department === 'Editing');
                const diretorDeArte = crew.filter(c => c.known_for_department === 'Art');
                const produtorExecutivo = crew.filter(c => c.job === 'Executive Producer');
                const diretorDeFotografia = crew.filter(c => c.job === 'Director of Photography');
                const designDeProducao = crew.filter(c => c.job === 'Production Design');
                const efeitosVisuais = crew.filter(c => c.job === 'Visual Effects');
                const compositor = crew.filter(c => c.job === 'Original Music Composer');
                const estrelas = cast.filter(c => c.known_for_department === "Acting").slice(0, 30);
                const som = crew.filter(c => c.known_for_department === 'Sound');
                const maquiagem = crew.filter(c => c.known_for_department === 'Costume & Make-Up');

                setStaff({
                    compositor,
                    designDeProducao,
                    diretor,
                    diretorDeArte,
                    diretorDeFotografia,
                    editor,
                    efeitosVisuais,
                    estrelas,
                    maquiagem,
                    produtorExecutivo,
                    roteiro,
                    som
                });
            }
        });

        getMovieImages(params.id).then(result => {
            if (result && result.payload && result.payload.data) {
                const { backdrops = [], posters = [] } = result.payload.data;

                setMovieImages(result.payload.data || []);
                setBackdrop(imagemAleatorea(backdrops).file_path);
                setPoster(imagemAleatorea(posters).file_path);

            }
        });


        // getMovieSimilar(params.id).then(async result => {

        //     const { genres } = systemData;//

        //     if (result && result.payload && result.payload.data) {
        //         let { results } = result.payload.data;
        //         popularGeneros(results, genres);
        //         popularLinkDetalhe(results, 'movie');
        //         popularTipo(results, 'movie');

        //         setSimilar(results);
        //     }
        // });

    }, [location]);

    useEffect(() => {

        const params = queryString.parse(location.search);

        const popularGeneros = (results, genres) => {

            for (let r of results) {

                r.genres = [];
                r.genre_ids && r.genre_ids.forEach(id => {
                    r.genres.push(genres.find(g => g.id === id))
                });
            }
        }

        const popularLinkDetalhe = (results, mediaType) => {

            for (let r of results) {
                let pathname = '/filme';
                if (mediaType.toLowerCase() === 'tv') {
                    pathname = 'serie';
                }
                r.linkDetalhe = `${pathname}/?id=${r.id}&type=${mediaType}`;
            }
        }

        getMovieSimilar(params.id).then(async result => {

            const { genres } = systemData;//

            if (result && result.payload && result.payload.data) {
                let { results } = result.payload.data;

                popularGeneros(results, genres);
                popularLinkDetalhe(results, 'movie');
                popularTipo(results, 'movie');

                setSimilar(results);
            }
        });

    }, [location, systemData]);


    const imagemAleatorea = (images) => {
        const tamanho = images.length;
        if (tamanho === 0) {
            return {};
        }
        const index = Math.random() * tamanho;
        return images[Math.floor(index)]
    }



    return !filme ? <></> : (
        <div>
            <Helmet>
                <title> AssistaMais - {filme.title}</title>
                <meta name="description" content={filme.overview} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={`Assista Mais - ${filme.title || filme.original_name}`} />
                <meta property="og:description" content={filme.overview} />
                <meta property="og:image" content={`${config.bigImageURL}${filme.backdrop_path}`} />

            </Helmet>
            <VideoPopup url={trailer} ref={videoPopupRef} controls={true} allowClose={true} />
            <Menu simples={true} pesquisa={false} ref={menuRef} />
            <ImagemFundoDetalhe img={`${config.bigImageURL}${filme.backdrop_path}`} className='detalhe-filme-imagem-fundo' />
            <DadosGerais filme={filme} poster={poster} staff={staff} />
            <div className="coluna">
                <OndeAssistir providers={providers} />
                <ClassificarCompartilhar menuRef={menuRef} id={filme.id} tipo="MOVIE" filme={filme} />
                <ListaAnunciosHorizontal anuncios={anuncios} key="lista-anuncios" onCheckMonetizado={() => carregarAnuncios()} />

                <SobreFilmeSerie filme={filme} poster={poster} staff={staff} backdropPath={backdrop || filme.backdrop_path} trailer={trailer} videoPopupRef={videoPopupRef} />
                <GaleriaImagens titulo="Galeria de imagens" imagens={movieImages?.backdrops} />
                <CamadaRelacionados filmes={similar || []} handleRelacionadosClick={handleRelacionadosClick} />
            </div>
            <div className="mobile">
                <OndeAssistir providers={providers} />
                <ClassificarCompartilhar menuRef={menuRef} id={filme.id} tipo="MOVIE" filme={filme} />
                {/* <ListaAnunciosHorizontal anuncios={anuncios} key="lista-anuncios" onCheckMonetizado={() => carregarAnuncios()} /> */}
                <div className="card-post-publicidade">

                    <ListaAnunciosHorizontal anuncios={anuncios} key="lista-anuncios" onCheckMonetizado={() => carregarAnuncios()} />
                </div>

                <SobreFilmeSerie filme={filme} poster={poster} staff={staff} backdropPath={backdrop || filme.backdrop_path} trailer={trailer} videoPopupRef={videoPopupRef} />
                <GaleriaImagens titulo="Galeria de imagens" imagens={movieImages?.backdrops} />
                <CamadaRelacionados filmes={similar || []} handleRelacionadosClick={handleRelacionadosClick} />
            </div>
            <RodapeConta />
        </div>


    );
}


DetalheDoFilme.propTypes = {

};

