import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './style.scss';

export default function CardResultadoBusca({ posterPath, name, onClick, className }) {

    return (
        <div className="card-box-resultado-busca" onClick={onClick}>
            <div className="card-body-resultado-busca" style={{ height: undefined, }} >
                <LazyLoadImage src={`https://image.tmdb.org/t/p/w220_and_h330_face//${posterPath}`} alt={name} className="card-image-poster-resultado-busca" />
            </div>
        </div>
    );
}