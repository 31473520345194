import { useEffect, useState } from 'react';
import "react-multi-carousel/lib/styles.css";
import Card from '../../atoms/Card';
import CardResultadoBusca from '../../atoms/CardResultadoBusca';
import './style.scss';



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1367 },
        items: 8,
        slidesToSlide: 8
    },
    desktop: {
        breakpoint: { max: 1366, min: 1024 },
        items: 8,
        slidesToSlide: 8
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3
    }
};

const ListaFilmesGrid = ({ filmes, titulo, notMouseOver = false, onClick, onClickVerMais, onMouseOver, onMouseLeave }) => {


    const [listaFilmes, setListaFilmes] = useState([]);
    useEffect(() => {
        setListaFilmes(filmes);
    }, [filmes]);

    const handleMouseOver = (e, f, rect) => {
        if (onMouseOver)
            return onMouseOver(e, f, rect)
        return;
    }

    return (
        <div className="container-lista-filme-grid">

            <div className="grid">
                {listaFilmes.map((f) => !notMouseOver ?

                    <Card
                        className='card-custom'
                        onClick={(e) => onClick(e, f)}
                        notMouseOver={notMouseOver}
                        onMouseOver={(e, rect) => handleMouseOver(e, f, rect)}
                        posterPath={f.poster_path}
                        data={f}
                        name={f.title || f.name}
                        linkDetalhe={f.linkDetalhe} /> :
                    <CardResultadoBusca
                        onClick={() => onClick(f)}
                        posterPath={f.poster_path}
                        name={f.title || f.name}
                        providers={f.providers || {}} />
                )}
            </div>
            {/* </Carousel> */}



        </div>
    )
};

export default ListaFilmesGrid;