import { useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from '../../atoms/Card';
import CardResultadoBusca from '../../atoms/CardResultadoBusca';
import './style.scss';



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1367 },
        items: 8,
        slidesToSlide: 8
    },
    desktop: {
        breakpoint: { max: 1366, min: 1024 },
        items: 8,
        slidesToSlide: 8
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4,
        slidesToSlide: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        slidesToSlide: 2,
        items: 2
    }
};


const ListaFilmesHorizontal = ({ filmes, titulo, notMouseOver = false, onClick, onClickVerMais, onMouseOver, onMouseLeave, cardPopup, ...rest }) => {


    const [listaFilmes, setListaFilmes] = useState([]);

    useEffect(() => {
        setListaFilmes(filmes);
    }, [filmes]);

    const handleMouseOver = (e, data, { top, left, width: cardWidth, height: cardHeight }) => {
        const { scrollY, scrollX } = window;

        const width = document.body.clientWidth;
        const multiplicador = width > 1366 ? 1 : 0.71;

        let x = scrollX + left + cardWidth / 2 - (432 * multiplicador) / 2;
        let y = scrollY + top + cardHeight / 2 - (481 * multiplicador) / 2;

        if (x < 0) x = 0;
        if (y < 0) y = 0;

        if (x + (432 * multiplicador) > width) {
            x = x - (x + (432 * multiplicador) - width) / 2 - 70 * multiplicador;
        }

        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    return !filmes || filmes.length === 0 ? <></> : (
        <div className="containerListaFilmeHorizontal" {...rest}>
            <div className="titulo">
                <div className="Text-Style-2">{titulo}</div>
                {onClickVerMais ?
                    <div className="Text-Style-3 titulo-ver-mais" onClick={onClickVerMais}>Ver todos</div>
                    :
                    <div className="Text-Style-3 titulo-ver-mais" ></div>
                }
            </div>

            <Carousel responsive={responsive}
                swipeable={true}
                draggable={true}
                infinite={true}
                centerMode={true}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={120000}
                removeArrowOnDeviceType={["tablet", "mobile"]}
            //deviceType={this.props.deviceType||''}
            >
                {listaFilmes.map((f) => !notMouseOver ?
                    <Card
                        key={`card-filme-${titulo}-${f.id}`}
                        onClick={(e) => onClick(e, f)}
                        notMouseOver={notMouseOver}
                        onMouseOver={(e, rect) => handleMouseOver(e, f, rect)}
                        posterPath={f.poster_path}
                        data={f}
                        name={f.title || f.name}
                        linkDetalhe={f.linkDetalhe} /> :
                    <CardResultadoBusca
                        onClick={() => onClick(f)}
                        posterPath={f.poster_path}
                        name={f.title || f.name}
                        providers={f.providers || {}} />
                )}

            </Carousel>


        </div>
    )
};

export default ListaFilmesHorizontal;