import React from 'react';

import './style.scss';

const CardPopupSelecaoProvider = ({ providers=[] }) => {
    return (
    <div className="card-popup-selecao-provider">
        {providers.map(p => <div></div>)}
    </div>
    )
}


export default CardPopupSelecaoProvider;