import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import assistaMais from '../../../assets/img/logo-rodape-assista.png';
import useMais from '../../../assets/img/usemaisnova.png';
import { getLinkUseMais } from '../../../actions/assistamais/api';
import './style.scss';


const ColunaRodapeOutrosPerfil = ({className = '',classNameImg ='',showText,classNameBody=''}) => {
    const handleClickUseMais = async () => {
        const linkData = await getLinkUseMais();
        window.open(linkData.payload.url,'_blank');
    }
    return (
        <div className={`coluna-rodape-outros-container ${classNameBody}`}>
            <div className={`coluna-rodape-outros ${classNameBody}`}>
                <div className='assista-mais-container'>
                    <img className={`assista-mais ${classNameImg}`} src={assistaMais} alt="logo assista mais" />
                    <div className="registro">
                        <p>Use Mais Multiserviços Ltda.</p>
                        <p>Film data from TMDB. </p>
                    </div>
                </div>
                {/* <div className="apresentacao">
                    Uma plataforma guia de entretenimento que facilita a jornada do usuário na busca por filmes e séries, e oferece resgate de prêmios através do <b>Programa Use Mais Pontos</b>.
                </div> */}

                <div className="apresentacao-contato">
                    {showText ? (
                        <div className="apresentacao-pontos">
                            Uma plataforma guia de entretenimento que facilita a busca por filmes, séries e games. Além de tudo oferece bônus de um incrível programa de pontos.
                        </div>
                    ): (
                        <div className="apresentacao">
                            Uma plataforma guia de entretenimento que facilita a busca por filmes e séries, games. Além de tudo oferece bônus de um incrível Programa de Pontos.
                        </div>
                    
                    )}

                    {showText ? (
                        <div className="contato-pontos">
                            <span>
                                Precisa de ajuda ou tem alguma sugestão? Envie um e-mail:
                                <a href="mailto:contato@assistamais.net.br">contato@assistamais.net.br</a>
                            </span>
                        </div>
                    ): (
                        <div className="contato">
                            <span>
                                Precisa de ajuda ou tem alguma sugestão? Envie um e-mail:
                                <a href="mailto:contato@assistamais.net.br">contato@assistamais.net.br</a>
                            </span>
                            
                        </div>
                    
                    )}
                </div>
                

                <div className='use-mais-container'>
                    <img className="use-mais" src={useMais} alt="logo use mais" />
                    {showText ? (
                        <div className="contato-pontos-usemais">
                            <a href="https://www.usemaisstore.com.br/" rel={'noopener noreferrer'} onClick={handleClickUseMais}>www.usemaisstore.com.br</a>
                        </div>
                    ): (
                        <div className="contato">
                            <a href="https://www.usemaisstore.com.br/" rel={'noopener noreferrer'} onClick={handleClickUseMais}>www.usemaisstore.com.br</a>
                        </div>
                    
                    )}
                </div>

            </div>
            
            

            <div className="rede-social">
                <a href="https://www.facebook.com/use.mais.multisservico" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} size="xl"/></a>
                <a href="https://www.instagram.com/assistamaisoficial/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} size="xl"/></a>

            </div>
        </div>
    )
};

export default ColunaRodapeOutrosPerfil;