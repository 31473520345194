import React, { useContext, useEffect } from 'react';
import setaParaBaixo from '../../assets/img/arrow-down.png';
import engrenagem from '../../assets/img/features-ico.png';
import busqueFilmes from '../../assets/img/ilustracao-busque-filmes.png';
import fiqueAntenado from '../../assets/img/ilustracao-fique-antenado.png';
import programaPontos from '../../assets/img/ilustracao-programa-pontos.png';
import linha1 from '../../assets/img/linha-1.png';
import linha2 from '../../assets/img/linha-2.png';
import fundoComoFunciona from '../../assets/img/slider_home_how_to.png';
import ImagemFundoDetalhe from '../../componentes/organisms/ImagemFundoDetalhe';
import Menu from '../../componentes/organisms/Menu';
import Rodape from '../../componentes/organisms/Rodape';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import VideoPlayer from '../../componentes/organisms/VideoPlayer';
import { SessionContext } from '../../contexts/SessionContext';
import './style.scss';


export default function ComoFunciona({ location }) {

    const [, , , , getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    return (
        <div>
            <Menu simples={true} pesquisa={false} className='como-funciona-menu' />
            <ImagemFundoDetalhe img={fundoComoFunciona} className="como-funciona-fundo" >
                <div className="como-funciona-titulo"> Como funciona</div>
            </ImagemFundoDetalhe>
            <div className={"como-funciona-body"}>
                <div className={"como-funciona-container"}>


                    <div className="resumo">
                        

                        <div className="resumo-content">

                            <VideoPlayer 
                            className={"video-player"} 
                            url="https://www.youtube.com/watch?v=Ld_5weWjW3Y" 
                            width="100%" 
                            height="100%"
                            controls="true"
                            
                            />

                            <div className="texto-informativo">
                            <div className="titulo-resumo">Programa Use Mais Pontos</div>
                                Se você passa muito tempo escolhendo o que assistir, e às vezes acaba dormindo ou desistindo antes de encontrar o que procurava, agora não precisa mais perder tempo fazendo essa busca. <br /><br/>
                                O Assista Mais veio para simplificar suas escolhas. Conheça nosso guia completo e detalhado das obras disponíveis nas principais plataformas de streaming de vídeo. <br /><br/>
                                Oferecemos um exclusivo programa de pontos, basta você interagir com os anúncios dos nossos parceiros, e ir juntando pontos para trocar por assinaturas de streaming, vale presente de alimentação, transporte, compras, games e muito mais.
                            </div>
                        </div>

                        
                    </div>


                    <div className="seta-baixo">
                        <img src={setaParaBaixo} alt="Seta para baixo" />
                    </div>

                    <div className="sessao-1">
                        <div className="parte-1">
                            <div className="titulo-parte-1"> Descubra e Organize seus favoritos</div>
                            <div className="texto-parte-1"> Você pode organizar sua busca, ou selecionar filtros que facilitam a procura pelo seu próximo filme ou série. Crie sua lista de favoritos, ou confira nossas sugestões para quando estiver sem a mínima ideia do que assistir. </div>
                        </div>
                        <div className="parte-2">
                            <img src={busqueFilmes} alt="Busque Filmes" />
                        </div>
                    </div>

                    <div className="linha-sessao-1">
                        <img src={linha1} alt="Linha 1" />
                    </div>

                    <div className="sessao-2">
                        <div className="parte-1">
                            <img src={fiqueAntenado} alt="beta" />
                        </div>
                        <div className="parte-2">
                            <div className="titulo-parte-2">Fique antenado</div>
                            <div className="texto-parte-2">Com as constantes atualizações dos catálogos das plataformas de streaming, aqui você não fica perdido, encontra o que procura, assiste ao trailer, vê a sinopse, confere a galeria de fotos e as avaliações dos principais sites de conteúdo e críticas de cinema. Tudo que você precisa em um só lugar.</div>
                            <div className="sessao-2-mobile">
                                <img src={fiqueAntenado} alt="beta" />
                            </div>
                        </div>
                    </div>

                    <div className="linha-sessao-2">
                        <img src={linha2} alt="Linha 2" />
                    </div>

                    <div className="sessao-3">
                        <div className="parte-1">
                            <div className="titulo-parte-3">Programa Use Mais Pontos</div>
                            <div className="texto-parte-3">O Assista Mais oferece um exclusivo programa de pontos, basta você interagir com os anúncios dos nossos parceiros, e ir juntando pontos para trocar por assinaturas de streaming, vale presente de alimentação, transporte, compras, games e muito mais.</div>

                            <div className="sessao-3-mobile">
                                <img src={programaPontos} alt="versão beta" />
                            </div>

                        </div>

                        <div className="parte-2">
                            <img src={programaPontos} alt="versão beta" />
                        </div>
                    </div>

                    <div className="sessao-4">
                        <div className="titulo-parte-4">Conheça o Programa Use Mais Pontos:</div>
                        <div className="texto-parte-4">É fácil acumular pontos e resgatar as recompensas.</div>
                    </div>

                    <div className="engrenagem">
                        <img src={engrenagem} alt="Linha 2" />
                    </div>

                    <div className="sessao-5">
                        <div className="titulo-parte-5">Saiba como juntar pontos</div>
                        <div className="texto-parte-5-1"><span>1.</span> Cadastre-se no Portal Assista Mais. </div>
                        <div className="texto-parte-5-2"><span>2.</span> Encontre as áreas reservadas para anúncios nas páginas da Assista Mais, escolha um vídeo ou banner sinalizado como gerador de pontos e clique.</div>
                        <div className="texto-parte-5-3"><span>3.</span> Pronto. Após clicar no banner e ser direcionado ou assistir ao vídeo por completo, você já ganha pontos, fácil assim!</div>
                        <div className="texto-parte-5-3"><span></span>Você pode acumular pontos de acordo com a disponibilidade de anúncios pontuáveis.</div>

                    </div>


                    <div className="sessao-6">
                        <div className="titulo-parte-6">E como trocar os pontos por recompensas?</div>
                        <div className="texto-parte-6-1"><span>1.</span> Acesse a sua conta para conhecer os itens disponíveis. </div>
                        <div className="texto-parte-6-3"><span>2.</span> Consulte o saldo acumulado em pontos. </div>
                        <div className="texto-parte-6-2"><span>3.</span> Escolha a recompensa ideal e troque seus pontos. <br />
                            <br />
                            O prazo para concluir o resgate é de até 10 dias úteis.</div>
                    </div>

                    <div className="sessao-7">
                        <div className="titulo-parte-7">Agora é só continuar juntando pontos e trocando por novas recompensas, e o melhor de tudo, que além de fácil, <b>é grátis!</b></div>
                    </div>


                </div>



            </div>
            <RodapeConta />
        </div>

    );
}

ComoFunciona.propTypes = {

};

