import { useEffect } from "react";
import { useContext } from "react";
import { obterInformacoesCEP } from "../../../actions/assistamais/api";
import { MessageContext } from "../../../contexts/MessageContext";
import { useForm } from "../../../hooks/useForm";
import { UFs } from "../../../utils/UFs";
import BotaoSomenteTexto from "../../atoms/BotaoSomenteTexto";
import Input from "../../atoms/Input";
import InputSelect from "../../atoms/InputSelect";

export default function Endereco({ disabled, continuarClick, onBlockedClick }) {

    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
        updateData,
    } = useForm({ // the hook we are going to create
        validations: { // all our validation rules go here
            // endereco: {
            //     required: {
            //         value: true,
            //         message: 'Preencha o endereço',
            //     },
            // },
            // bairro: {
            //     required: {
            //         value: true,
            //         message: 'Preencha o bairro',
            //     },
            // },
            // cep: {
            //     required: {
            //         value: true,
            //         message: 'Preencha o CEP',
            //     },
            // },
            // cidade: {
            //     required: {
            //         value: true,
            //         message: 'Preencha a cidade',
            //     },
            // },
            // uf: {
            //     required: {
            //         value: true,
            //         message: 'Preencha o estado',
            //     },
            // },
        },
        onSubmit: () => {
            continuarClick(data)
        },
        initialValues: { // used to initialize the data
            endereco: '',
            bairro: '',
            cep: '',
            cidade: '',
            uf: '',
        },

    });

    const [showMessage, showError, showMessageFromResult] = useContext(MessageContext);

    const handleBlurCep = (e) => {
        const cep = e.target.value;

        obterInformacoesCEP(cep).then(async result => {
            if (result.payload.status === 200) {
                const { bairro = '', cidade = '', logradouro: endereco = '', uf = '' } = result.payload.data;
                updateData({ bairro, cidade, endereco, uf })
            } else {
                showError('CEP não localizado')
            }

        });
    }

    const handlePrecadastro = () => {

        const { bairro = '', cidade = '', logradouro: endereco = '', uf = '', cep = '' } = JSON.parse(localStorage.getItem('preCadastro'));
        if( cep !== '' && data['uf'] === '')
            updateData({ bairro, cidade, endereco, uf, cep })
    }

    return (

        <div className="dados-pessoais">
            {disabled && <div className="camuflar" onClick={onBlockedClick} />}
            <div className="titulo-bloco">Endereço</div>
            <form onSubmit={handleSubmit}>
                <div className="fazer-cadastro-inputs">
                    <div className="row">
                        <Input onFocus={handlePrecadastro} type="text" className="w50" label="Digite seu CEP" value={data['cep']} onChange={handleChange('cep')} onBlur={handleBlurCep} error={errors} name="cep" />
                    </div>
                    <div className="row">
                        <Input type="text" className="w100" label="Digite seu endereço" value={data['endereco']} onChange={handleChange('endereco')} error={errors} name="endereco" />
                    </div>
                    <div className="row">
                        <Input type="text" className="w100" label="Digite seu bairro" value={data['bairro']} onChange={handleChange('bairro')} error={errors} name="bairro" />
                    </div>
                    <div className="row">
                        <Input type="text" className="w50" label="Digite o nome da sua cidade" value={data['cidade']} onChange={handleChange('cidade')} error={errors} name="cidade" />
                        <InputSelect type="select" className="w50" placeholder="" label="Selecione seu estado" value={data['uf']} onChange={handleChange('uf')} error={errors} name="uf" >
                            <option></option>
                            {UFs.map(uf =>
                                <option>{uf.sigla}</option>
                            )}
                        </InputSelect>

                    </div>

                    <BotaoSomenteTexto className="botao" value="Continuar" type="submit" />

                </div>
            </form>
        </div>
    )
};

