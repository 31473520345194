import Classificacao from '../../atoms/Classificacao';
import LabelQuadrado from '../../atoms/LabelQuadrado';
import './style.scss';

const LinhaInformativa = ({ generos = [], tempoDeFilme, ano, codigoDaClassificacao, onSubmit }) => {
    //obterClassificacao(filme.release_dates)
    return (
        <div className="linha-informativa">
            {generos?.map(g => (
                <LabelQuadrado className="linha-informativa-label" key={`lq_genero_id_${g.id}`}>{g.name}</LabelQuadrado>
            ))}

            <LabelQuadrado className="linha-informativa-label" >{ano}</LabelQuadrado>
            { tempoDeFilme &&  <LabelQuadrado className="linha-informativa-label">{tempoDeFilme}</LabelQuadrado> }

            <Classificacao className="linha-informativa-classificacao" codigo={codigoDaClassificacao} />

        </div>
    )
};

export default LinhaInformativa;