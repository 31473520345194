import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { obterAnuncios } from '../../actions/assistamais/api';
import { discoverMovie } from '../../actions/tmdb';
import fundo from '../../assets/img/pexels-august-de-richelieu-4260642.png';
import ListaTitulosGrid from '../../componentes/templates/ListaTitulosGrid';
import { SessionContext } from '../../contexts/SessionContext';
import { popularTipo } from '../../utils/titulos';
import './style.scss';

export default function TituloPorGeneroGenero({ location }) {

    const [titulos, setTitulos] = useState([]);
    const [generoSelecionado, setGeneroSelecionado] = useState("");
    const [genero2Selecionado, setGenero2Selecionado] = useState("");

    const [anuncios, setAnuncios] = useState([]);

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    const popularGeneros = (results, genres) => {

        for (let r of results) {

            r.genres = [];
            r.genre_ids && r.genre_ids.forEach(id => {
                r.genres.push(genres.find(g => g.id === id))
            });
        }
    }

    const popularLinkDetalhe = (results, mediaType) => {

        for (let r of results) {
            let pathname = '/filme';
            if (r.tipo.toLowerCase() === 'tv') {
                pathname = '/serie';
            }
            r.linkDetalhe = `${pathname}/?id=${r.id}&type=${r.tipo}`;
        }
    }

    const carregarAnuncios = () => {
        try {

            obterAnuncios('TITULOS-POR-GENERO-X-GENERO').then(result => {
                setAnuncios(result.payload.data.results[0].linha);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        const params = queryString.parse(location.search);
        const genero = systemData.genres.filter(g => g.id === params.genero * 1);

        if (genero && genero.length > 0) {
            setGeneroSelecionado(genero[0].name);
        }

        const genero2 = systemData.genres.filter(g => g.id === params.genero2 * 1);
        if (genero2 && genero2.length > 0) {
            setGenero2Selecionado(genero2[0].name);
        }
    }, [systemData, location]);

    useEffect(() => {
        /*
        plataforma
        genero
         */
        const params = queryString.parse(location.search);

        window.scrollTo(0, 0);

        carregarAnuncios();

        async function fetchData() {



            discoverMovie(`&with_genres=${params.genero},${params.genero2}&watch_region=BR&`).then(result => {

                let { results } = result.payload.data;

                popularGeneros(results, systemData.genres);
                popularLinkDetalhe(results, 'movie');
                const pagina1 = results;

                discoverMovie(`&with_genres=${params.genero},${params.genero2}&watch_region=BR&`, 2).then(result => {

                    let { results = [] } = result.payload.data;

                    popularGeneros(results, systemData.genres);
                    popularLinkDetalhe(results, 'movie');
                    setTitulos([...pagina1, ...results])
                });
            });

        }
        fetchData()

    }, [location]);

    return <ListaTitulosGrid
        titulos={titulos}
        tituloPagina={generoSelecionado}
        fundo={fundo}
        subtituloPagina=""
        destaqueSubtitulo={`& ${genero2Selecionado}`}
        anuncios={anuncios}
        onCheckMonetizado={() => carregarAnuncios()}
    />



}


TituloPorGeneroGenero.propTypes = {

};

