import { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import config from "../../../config/default";
import Anuncio from '../../atoms/Anuncio';
import AnuncioTiraCustom from "../../atoms/AnuncioTiraCustom";
import './style.scss';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1367 },
        items: 4,
        slidesToSlide: 4
    },
    desktop: {
        breakpoint: { max: 1366, min: 1024 },
        items: 4,
        slidesToSlide: 4
    },
    tablet: {
        breakpoint: { max: 991, min: 464 },
        items: 1,
        slidesToSlide: 1,

    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        slidesToSlide: 1,
        items: 1,

    }
};


const ListaAnunciosHorizontalDuasPosicoes = ({ onClick, onClickVerPlataforma, onCheckMonetizado, anuncios }) => {
    let contador = 0;

    const [anuncioId, setAnuncioId] = useState();
    const [anuncioId2, setAnuncioId2] = useState();
    const [anuncioIdMobile, setAnuncioIdMobile] = useState();

    useEffect(() => {
        if (anuncios.length > 3) {

            setAnuncioId(anuncios[contador++].id);
            setAnuncioId2(anuncios[contador++].id);

            setAnuncioIdMobile(anuncios[contador++].id);

        }

        const interval = setInterval(() => {
            if (anuncios.length > 0) {
                setAnuncioId(anuncios[contador++].id);
                    if (contador >= anuncios.length) contador = 0;

                setAnuncioId2(anuncios[contador++].id);
                    if (contador >= anuncios.length) contador = 0;

                setAnuncioIdMobile(anuncios[contador++].id)
                    if (contador >= anuncios.length) contador = 0;
            }

        }, config.tempoTransicaoAnuncio);

        return () => {
            clearInterval(interval);
        };
    }, [anuncios])

    return (
        <div className="container-lista-anuncio-horizontal">
            <div className="lista-anuncios-horizontal-titulo">PUBLICIDADE</div>
            <div className="lista-anuncios hideInMobile">
                {anuncios && anuncios.map((a) =>
                    <AnuncioTiraCustom 
                    key={`anuncio-lista-${a.id}`} 
                    anuncio={a} 
                    onCheckMonetizado={onCheckMonetizado}
                    style={{ 
                        display: 
                        a.id === anuncioId || 
                        a.id === anuncioId2 ? '' : 'none', width: 550 }}
                     />
                )}
            </div>

            <div className="lista-anuncios showInMobile lista-anuncios-mobile">

            {/* {anuncios && anuncios.map((a) =>
                    <Anuncio 
                    key={`anuncio-lista-mobile-${a.id}`} 
                    anuncio={a} 
                    onCheckMonetizado={onCheckMonetizado}
                    style={{ 
                        display: 
                        a.id === anuncioId || 
                        a.id === anuncioId2 ||
                        a.id === anuncioId3 ||
                        a.id === anuncioId4 ||
                        a.id === anuncioId5 ? '' : 'none' }}
                     />
                )} */}
                {anuncios && anuncios.map((a) =>
                    <AnuncioTiraCustom key={`anuncio-lista-mobile-${a.id}`} anuncio={a} onCheckMonetizado={onCheckMonetizado} style={{ display: a.id === anuncioIdMobile ? '' : 'none' }} />
                )}

            </div>
        </div>
    )
};

export default ListaAnunciosHorizontalDuasPosicoes;