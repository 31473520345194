import React from 'react';
import './style.scss';


export default function BotaoSomenteTexto({ className = '', onClick, value, ...rest }) {

    return (
        <button className={`botao-somente-texto ${className}`} onClick={onClick}>
            <div className="text">{value}</div>
        </button>
        // <div className={`botao-somente-texto ${className}`} onClick={onClick}>
        //     <div className="text">{value}</div>
        // </div>
    );
}