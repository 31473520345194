import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import './style.scss';

const ColunaRodapeLinksPerfil = ({ titulo, subtitulo = '', itens = [], link, classNameTitulo='',className='', itensGeneros = null, itensPlataformas = null }) => {

    const [mobileOpen, setMobileOpen] = useState(false);
    const [showGenres, setShowGenres] = useState(false);
    const [showPlatforms, setShowPlatforms] = useState(false);

    const handleClick = () => {
        setMobileOpen(!mobileOpen);
    }

    const handleClickArrow = (type) => {
        if (type == 'generos') {
            setShowGenres(!showGenres)
        } else {
            setShowPlatforms(!showPlatforms)
        }
    }

    return (
        <div className={`coluna-rodape-links ${className}`}>
            <div className={`titulo ${classNameTitulo}`} onClick={handleClick}>{titulo}</div>
            <div className="subtitulo items hideInMobile">
                <div >{subtitulo} </div>
            </div>

            {itensGeneros != null && itensPlataformas != null ?
                (
                    <>
                        <div className={`items newItem ${mobileOpen ? '' : 'hideInMobile'}`} >
                            <div className='descriptionItem' onClick={() => handleClickArrow('generos')}>
                                <span className={showGenres ? 'show' : ''}>GÊNEROS</span><FontAwesomeIcon icon={!showGenres ? faSortDown : faSortUp} size="lg" />
                            </div>
                            <ul className={showGenres ? 'show' : ''}>
                                {itensGeneros.map(i => (
                                    <li key={`rodape-${i.id}`}><a href={i.link}>{i.texto}</a></li>
                                ))}
                            </ul>
                        </div>

                        <div className={`items newItem ${mobileOpen ? '' : 'hideInMobile'}`} >
                            <div className='descriptionItem' onClick={() => handleClickArrow('plataformas')} >
                                <span className={showPlatforms ? 'show' : ''}>PLATAFORMAS</span><FontAwesomeIcon icon={!showPlatforms ? faSortDown : faSortUp} size="lg"/>
                            </div>
                            <ul className={showPlatforms ? 'show' : ''}>
                                {itensPlataformas.map(i => (
                                    <li key={`rodape-${i.id}`}><a href={i.link}>{i.texto}</a></li>
                                ))}
                            </ul>
                        </div>
                    </>
                    
                )
            :
                (
                    <div className={`items ${mobileOpen ? '' : 'hideInMobile'}`} >
                        {itens.map(i => (
                            <div key={`rodape-${i.id}`} className="itemsDescubra"><a href={i.link}>{i.texto}</a></div>
                        ))}
                    </div>
                )
            }

            

        </div >
    )
};

export default ColunaRodapeLinksPerfil;