import NewCard from '../../atoms/NewCard';
import './style.scss';

const ListaNoticiasHorizontal = ({ onClick, onClickVerPlataforma, items = [] }) => {

    return (
        <div className="container-lista-noticia-horizontal">
            <div className="lista-noticias">
                {items.map(i =>
                    <NewCard onLeiaMaisClick={() => onClick(i.id)}
                        dataPostagem=""
                        tituloNoticia={i.name}
                        resumoNoticia={i.descricao}
                        img={i.src}
                    />
                )}


            </div>
        </div>
    )
};

export default ListaNoticiasHorizontal;
