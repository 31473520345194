import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { apagarUsuario, editarPerfil, obterInformacoesCEP, obterPerfl, enviarSms, validarSms } from '../../actions/assistamais/api';
import excluir from '../../assets/img/excluir.png';
import BotaoArredondado from '../../componentes/atoms/BotaoArredondado';
import DialogPopup from '../../componentes/atoms/DialogPopup';
import Input from '../../componentes/atoms/Input';
import InputCheck from '../../componentes/atoms/InputCheck';
import InputSelect from '../../componentes/atoms/InputSelect';
import SetaVoltar from '../../componentes/atoms/SetaVoltar';
import Menu from '../../componentes/organisms/Menu';
import PopupSms from '../../componentes/organisms/PopupSms';
import Rodape from '../../componentes/organisms/Rodape';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import TrocarSenha from '../../componentes/organisms/TrocarSenha';
import { MessageContext } from '../../contexts/MessageContext';
import { SessionContext } from '../../contexts/SessionContext';
import { useForm } from '../../hooks/useForm';
import { mask, onlyNumbers } from '../../utils';
import { gravarDados, logout, obterDados } from '../../utils/Session';
import { UFs } from '../../utils/UFs';
import './style.scss';

export default function EditarPerfil() {
    
    const [showMessage, showError, showMessageFromResultRequest] = useContext(MessageContext);

    const [showSms, setShowSms] = useState(false);
    const [validaSms, setValidaSms] = useState(false);

    const [session, , , , getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);
    
    
    let history = useHistory();
    const trocarSenhaRef = useRef();
    
    const dialogRef = useRef();
    
    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
        changeData,
        updateData,
        changeField
    } = useForm({ // the hook we are going to create
        validations: { // all our validation rules go here
            name: {
                required: {
                    value: true,
                    message: 'Preencha o nome',
                },
                custom: {
                    isValid: (value) => {
                        const newName = value.split(' ');
                        
                        if(newName.length >= 2){
                            return newName[1].length > 0 && newName[0].length > 0
                        } else {
                            return false
                        }
                    },
                    message: 'Preencha o nome completo',
                },
            },
            cpf: {
                required: {
                    value: true,
                    message: 'Preencha o CPF',
                },
                custom: {
                    isValid: (value) => {
                        return testaCPF(value)
                    },
                    message: 'Preencha com um CPF válido',
                },
            },
            genero: {
                required: {
                    value: true,
                    message: 'Preencha o genero',
                },
            },
            nascimento: {
                required: {
                    value: true,
                    message: 'Preencha a data de nascimento',
                },
            },
            celular: {
                required: {
                    value: true,
                    message: 'Preencha o celular',
                },
                custom: {
                    isValid: (value) => {
                        return value && onlyNumbers(value).length === 11;
                    },
                    message: 'Preencha com um telefone válido',
                },
            },
            logradouro: {
                required: {
                    value: true,
                    message: 'Preencha o logradouro',
                },
            },
            bairro: {
                required: {
                    value: true,
                    message: 'Preencha o bairro',
                },
            },
            cidade: {
                required: {
                    value: true,
                    message: 'Preencha a cidade',
                },
            },
            email: {
                required: {
                    value: true,
                    message: 'Preencha o e-mail',
                },
            },
            cep: {
                required: {
                    value: true,
                    message: 'Preencha o cep',
                },
            },
            uf: {
                required: {
                    value: true,
                    message: 'Preencha o estado',
                },
            },
        },
        onSubmit: () => {
            
            if(!validaSms){
                enviarSms({cpf: data.cpf, telefone: onlyNumbers(data.celular) }).then(result => {
                    if (result.payload.status * 1 < 200 || result.payload.status * 1 > 299) {
                        showError(result.payload.data.message)
                        if(result.payload.data.message === 'Você ainda possuí um código válido.'){
                            setShowSms(true);
                        }                        
                    } else {
                        setShowSms(true);
                    }
                });
                return
            }
            
            const body = {
                name: data.name,
                email: data.email,
                extra: {
                    cpf: data.cpf,
                    genero: data.genero,
                    nascimento: data.nascimento,
                    celular: onlyNumbers(data.celular),
                    autorizaReceberEmail: data.autorizaReceberEmail,
                    endereco: {
                        logradouro: data.logradouro,
                        bairro: data.bairro,
                        cidade: data.cidade,
                        cep: data.cep,
                        uf: data.uf
                    }
                }
            }
            editarPerfil(body).then(resultado => {
                if (Math.trunc(resultado.payload.status / 100) === 2) { // 2xx => Resultado ok
                    showMessage('Perfil atualizado com sucesso');
                    let session = obterDados();
                    gravarDados({ ...session, status: 'OK' });
                    setTimeout(() => window.location.href = '/minha-conta', 2000);
                } else {
                    showError(resultado.payload.data.message);
                }
            });
        },
        initialValues: { // used to initialize the data
            nome: '',
            email: '',
            telefone: '',
            cpf: ''
        },
    });
    
    const handleVoltarClick = () => {
        history.push('/minha-conta')
    }
    
    useEffect(() => {
        
        const dados = obterDados;
        
        obterPerfl().then((resultado) => {
            
            const { name, extra = {}, email } = resultado.payload.data;
            const { genero, nascimento = '', celular, endereco = {}, cpf, autorizaReceberEmail } = extra;
            const { logradouro = '', bairro = '', cidade = '', cep = '', uf = '' } = endereco;
            
            changeData({ name, 
                nascimento: nascimento !== null ?  nascimento.substr(0, 10) : '', 
                genero, 
                celular: celular !== '' ? aplicarMascaraTelefone(celular) : '', 
                logradouro, bairro, cidade, cep, uf, email, 
                cpf: session && session.status === 'INCOMPLETO' ? '' : cpf, 
                autorizaReceberEmail });
            })
        }, [])
        
        const handleBlurCep = (e) => {
            const cep = e.target.value;
            
            if (cep) {
                obterInformacoesCEP(cep).then(async result => {
                    if (result.payload.status === 200) {
                        const { bairro = '', cidade = '', logradouro, uf = '' } = result.payload.data;
                        updateData({ bairro, cidade, logradouro, uf })
                    } else {
                        showError('CEP não localizado')
                    }
                    
                });
            }
        }
        
        const handleTrocarSenhaClick = () => {
            trocarSenhaRef.current.open();
        }
        
        const handleExclusaoConfirm = async () => {
            if (showMessageFromResultRequest(apagarUsuario(), "Conta excluída com sucesso")) {
                logout();
                setTimeout(() => {
                    window.location.href = '/';
                }, 3000)
            }
        }
        
        const handleChangeCelular = (e) => {
            
            //console.log(e);
            const texto = onlyNumbers(e.target.value);
            return changeField('celular', aplicarMascaraTelefone(texto));
        }
        
        const aplicarMascaraTelefone = (texto) => {
            let textoAjustado;
            const tamanho = texto.length;
            
            const mascaraTelefone = '(##) ####-####';
            const mascaraCelular = '(##) #####-####';
            
            if (tamanho >= 11) {
                textoAjustado = mask(texto, mascaraCelular);
            } else if (tamanho > 0) {
                textoAjustado = mask(texto, mascaraTelefone);
            }
            
            return textoAjustado;
        }
        
        const closePupupSms = () => {
            
            // if(!validaSms){
            //     showError("Por favor realize a validação do token.")
            // }else{
            setShowSms(false)
            // }
            
        }
        
        const handleValidarClick = (codigo) => {
            
            validarSms({codigo: codigo, telefone: onlyNumbers(data.celular) }).then(result => {
                if (result.payload.status * 1 < 200 || result.payload.status * 1 > 299) {
                    showError(result.payload.data.message)
                } else {
                    setShowSms(false);
                    setValidaSms(true);
                    document.querySelector('#editar-perfil-btn-hack').click();
                }
            }); 
        }
        
        const testaCPF = (cpf) => {
            cpf = cpf.replace(/[^\d]+/g,'');	
            if(cpf == '') return false;	
            // Elimina CPFs invalidos conhecidos	
            if (cpf.length != 11 || 
                cpf == "00000000000" || 
                cpf == "11111111111" || 
                cpf == "22222222222" || 
                cpf == "33333333333" || 
                cpf == "44444444444" || 
                cpf == "55555555555" || 
                cpf == "66666666666" || 
                cpf == "77777777777" || 
                cpf == "88888888888" || 
                cpf == "99999999999" ||
                cpf == "12345678909")
                return false;
                // Valida 1o digito
                let add = 0;
                for (let i=0; i < 9; i ++)
                add += parseInt(cpf.charAt(i)) * (10 - i);
                let rev = 11 - (add % 11);
                if (rev == 10 || rev == 11)
                rev = 0;
                if (rev != parseInt(cpf.charAt(9)))
                return false;
                // Valida 2o digito
                add = 0;
                for (let i = 0; i < 10; i ++)
                add += parseInt(cpf.charAt(i)) * (11 - i);
                rev = 11 - (add % 11);
                if (rev == 10 || rev == 11)
                rev = 0;
                if (rev != parseInt(cpf.charAt(10)))
                return false;
                return true;  
        }
            
            return (
                
                <>
                {showSms &&
                    <PopupSms titulo="CÓDIGO" mensagem={`Para atualizar o perfil digíte o código Assista Mais que foi enviado por sms para o número ${data.celular}.`} onClose={closePupupSms} validarClick={handleValidarClick} />
                }
                
                <div className="editar-perfil">
                
                <Menu simples={false} pesquisa={false} className="custom-menu" />
                <TrocarSenha ref={trocarSenhaRef} />
                <div className="editar-perfil-container">
                
                <div className="form-inputs">
                <div className="form-input-topo">
                <div className="titulo-popup">
                <SetaVoltar className="seta-voltar-custom" onClick={handleVoltarClick} /> Editar Perfil</div>
                <div onClick={handleTrocarSenhaClick} className='alterar-senha'>ALTERAR SENHA</div>
                </div>
                {session && session.status === 'INCOMPLETO' &&
                <div className='mensagem-dados-incompleto'>Que bom ter você por aqui. Precisamos completar o seu cadastro para melhor aproveitamento da plataforma.</div>
            }
            <div className="inputs" >
            <form onSubmit={handleSubmit}>
            <div className="row">
            
            <Input label="Seu nome completo*" className="w50" value={data['name']} onChange={handleChange('name')} error={errors} name="name" />
            { session && session.status === 'INCOMPLETO' ?
            <Input label="Digite seu CPF sem traços ou pontos*"  maxLength="11" className="w50" onChange={handleChange('cpf')} value={data['cpf']} error={errors} name="cpf" />
            :
            <Input label="Digite seu CPF sem traços ou pontos*" maxLength="11" className="w50" value={data['cpf']} error={errors} name="cpf" />
        }
        
        </div>
        
        <div className="row">
        
        <Input type="number" min="1000000" max="99999999" label="Digite seu CEP*" className="w50" value={data['cep']} onChange={handleChange('cep')} error={errors} name="cep" onBlur={handleBlurCep} />
        <Input label="Digite seu endereço*" className="w50" value={data['logradouro']} onChange={handleChange('logradouro')} error={errors} name="logradouro" />
        </div>
        
        <div className="row">
        <Input label="Digite seu bairro*" className="w25" value={data['bairro']} onChange={handleChange('bairro')} error={errors} name="bairro" />
        <Input label="Digite o nome da sua cidade*" className="w50" value={data['cidade']} onChange={handleChange('cidade')} error={errors} name="cidade" />
        <InputSelect type="select" className="w25" placeholder="" label="Selecione seu estado*" value={data['uf']} onChange={handleChange('uf')} error={errors} name="uf" >
        <option></option>
        {UFs.map(uf =>
            <option>{uf.sigla}</option>
            )}
            </InputSelect>
            </div>
            
            <div className="row">
            <Input label="Digite sua data de nascimento*" type="date" className="w25" value={data['nascimento']} onChange={handleChange('nascimento')} error={errors} name="nascimento" />
            <InputSelect type="select" className="w25" placeholder="" label="Selecione seu gênero*" value={data['genero']} onChange={handleChange('genero')} error={errors} name="genero" >
            <option></option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
            <option value="NA">Prefiro não Informar</option>
            
            </InputSelect>
            <Input label="Digite um e-mail*" className="w25" type="email" value={data['email']} onChange={handleChange('email')} error={errors} name="email" />
            {/* <Input label="Digite um e-mail" type="email" className="w25" value={data['email']} error={errors} name="email" /> */}
            <Input label="Digite o número do seu telefone*" className="w25" value={data['celular']} onChange={handleChangeCelular} error={errors} name="celular" />
            {/* <InputMaskCustom label="Digite o número do seu telefone" className="w25" mask="(99) 99999 9999" maskChar=" " value={data['celular']} onChange={handleChange('celular')} name="celular" error={errors} /> */}
            
            </div>
            
            <div className="row">
            <InputCheck name="autorizaReceberEmail" onChange={handleChange('autorizaReceberEmail')} checked={data['autorizaReceberEmail']} error={errors} >
            <div className="termos"> Desejo receber conteúdo informativo e promoções por email</div>
            </InputCheck>
            </div>
            <div className="form-acao">
            <BotaoArredondado value="SALVAR" type="submit" className='editar-perfil-btn-hack' id='editar-perfil-btn-hack' />
            </div>
            </form>
            </div>
            </div>
            <div className='outras-opcoes'>
            <DialogPopup tipo="2" confirmLabel="SIM" ref={dialogRef} allowClose={true} title="Deseja realmente excluir sua conta?" subtitle="Todos os seus dados serão deletados da platafoma Assista Mais. É uma ação irreversível" onConfirm={handleExclusaoConfirm} />
            <div className='excluir-conta' onClick={() => dialogRef.current.show()}>
            <img src={excluir} alt="excluir" /> EXCLUIR CONTA
            </div>
            </div>
            </div>
            <RodapeConta />
            </div>
            
            </>
            );
        }
        
        
        
        