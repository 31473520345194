import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import CardPopup from '../../molecules/CardPopup';
import ImagemFundoDetalhe from '../../organisms/ImagemFundoDetalhe';
import ListaAnunciosMeiaTiraHorizontal from '../../organisms/ListaAnunciosMeiaTiraHorizontal';
import ListaFilmesGrid from '../../organisms/ListaFilmesGrid';
import Menu from '../../organisms/Menu';
import Rodape from '../../organisms/Rodape';
import ListaAnunciosHorizontalTresPosicoes from '../../organisms/ListaAnunciosHorizontalTresPosicoes';
import './style.scss';
import ListaAnunciosHorizontal from '../../organisms/ListaAnunciosHorizontal';
import RodapeConta from '../../organisms/RodapeConta';

export default function ListaTitulosGridRotativo({ fundo, titulos, tituloPagina, subtituloPagina, destaqueSubtitulo, anuncios, onCheckMonetizado }) {

    const cardPopup = useRef(null);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 961px)' });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = (e, data) => {

        const { innerWidth: width, innerHeight: height } = window;

        const x = e.pageX > width / 2 ? e.pageX - 432 + 20 : e.pageX;
        const y = e.clientY > height / 2 ? e.pageY - 481 + 20 : e.pageY;
        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    const handleMouseOver = (e, data, { top, left, width: cardWidth, height: cardHeight }) => {
        const { scrollY, scrollX } = window;

        const width = document.body.clientWidth;
        const multiplicador = width > 1366 ? 1 : 0.71;

        let x = scrollX + left + cardWidth / 2 - (432 * multiplicador) / 2;
        let y = scrollY + top + cardHeight / 2 - (481 * multiplicador) / 2;

        if (x < 0) x = 0;
        if (y < 0) y = 0;

        if (x + (432 * multiplicador) > width) {
            x = x - (x + (432 * multiplicador) - width) / 2 - 70 * multiplicador;
        }

        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }


    const cardsPorLinha = isTabletOrMobile ? 3 : 8;

    return (
        <div className="lista-titulos-plataforma">
            <Menu simples={false} />
            <ImagemFundoDetalhe img={fundo} className="lista-titulos-plataforma-fundo" opacity={false}>
                <div className="titulo-pagina">
                    <div className="titulo-principal">{tituloPagina}</div>
                    <div className="titulo-secundario">{subtituloPagina} <span>{destaqueSubtitulo}</span></div>
                </div>
            </ImagemFundoDetalhe>

            <CardPopup ref={cardPopup} />

            <ListaFilmesGrid onMouseOver={handleMouseOver} filmes={titulos.slice(0, cardsPorLinha)} onClick={handleClick} />

            {/* <ListaAnunciosMeiaTiraHorizontal anuncios={anuncios} onCheckMonetizado={onCheckMonetizado} /> */}
            {/* <ListaAnunciosHorizontalTresPosicoes onCheckMo  netizado={onCheckMonetizado} anuncios={anuncios} key="lista-anuncios" /> */}

            <ListaAnunciosHorizontal onCheckMonetizado={onCheckMonetizado} anuncios={anuncios} key="lista-anuncios" />

            <ListaFilmesGrid onMouseOver={handleMouseOver} filmes={titulos.slice(cardsPorLinha)} onClick={handleClick} />

            <RodapeConta />
        </div>
    );
}


ListaTitulosGridRotativo.propTypes = {

};

