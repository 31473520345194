import React, { useState } from 'react';

import './style.scss';

export default function TituloFilmeSerie({ children }) {

    return (
        <div className="titulo-filme-serie">
            {children}
        </div>
    );
}