import React, { useRef } from 'react';
import imagemFaq from '../../assets/img/image-faq.png';
import Faq from '../../componentes/molecules/Faq';
import ImagemFundoDetalhe from '../../componentes/organisms/ImagemFundoDetalhe';
import Menu from '../../componentes/organisms/Menu';
import Rodape from '../../componentes/organisms/Rodape';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import './style.scss';

export default function Ajuda() {

    const menuRef = useRef();

    return (
        <div>
            <Menu simples={true} pesquisa={false} />
            <ImagemFundoDetalhe img={imagemFaq} className="ajuda-fundo" >
                <h1> Perguntas Frequentes</h1>
            </ImagemFundoDetalhe>

            <div className={"ajuda-body"}>
                <div className={"ajuda-container"}>


                    <Faq className="faq-custom" menuRef={menuRef} />

                </div>



            </div>
            <RodapeConta />
        </div>

    );
}


Ajuda.propTypes = {

};

