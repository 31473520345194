import React from 'react';

import './style.scss';

export default function CustomDot({ onClick, ...rest }) {


    const {
        onMove,
        index,
        active,
        carouselState: { currentSlide, deviceType }
    } = rest;
    //const carouselItems = [CarouselItem1, CaourselItem2, CarouselItem3];
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
        <div 
            className={`custom-dot-item ${active ? "custom-dot-item-ativo" : ""} `}
            onClick={() => onClick()}
        >

        </div>
    );

}