import React, { useContext, useRef, useState } from 'react';
import { anuncioClick } from '../../../actions/assistamais/api';
import moneyOn from '../../../assets/img/money-on.png';
import { SessionContext } from '../../../contexts/SessionContext';
import { doLogin, gravarDados, obterDados } from '../../../utils/Session';
import VideoPopup from '../VideoPopup';
import './style.scss';
import BotaoArredondado from "../BotaoArredondado";
import Popup from "../../molecules/Popup";
import verified from '../../../assets/img/verified.png';

export default function AnuncioTiraCustom({ anuncio = {}, className = '', children, imagemCapa, alt, onCheckMonetizado, ...rest }) {

    const [session, setSession, logado] = useContext(SessionContext);
    const videoPopupRef = useRef()
    const avisoPontosPopupRef = useRef();

    const [showAvisoPontos, setShowAvisoPontos] = useState(false);
    const [receivedPoints, setReceivedPoints] = useState(0);

    const checkMonetizado = () => {

        if (anuncio.isMonetizado && onCheckMonetizado) {
            onCheckMonetizado();
        }
    }

    const handleClick = () => {
        if (!logado && anuncio.isMonetizado) {
            doLogin()
            return;
        }
        if (anuncio.modelo === 'LINK_EXTERNO') {
            if (anuncio.isMonetizado) {
                anuncioClick({ anuncioId: anuncio.id, token: anuncio.token }).then(() => {
                    checkMonetizado();
                });
            }
            window.open(anuncio.url, "_blank");
            setTimeout(() => {
                window.location.reload();
            },500);
        } else if (anuncio.modelo === 'LINK_INTERNO') {
            if (anuncio.isMonetizado) {
                anuncioClick({ anuncioId: anuncio.id, token: anuncio.token }).then(() => {
                    checkMonetizado();
                });
            }
            window.location.href = anuncio.url;
        }

        if (anuncio.modelo === 'VIDEO') {
            // setShowVideo(true);
            videoPopupRef.current.setAnuncioToken(anuncio.isMonetizado ? anuncio.token : null)
            videoPopupRef.current.setAnuncioLink(anuncio.link)
            videoPopupRef.current.setAnuncioId(anuncio.id)
            videoPopupRef.current.show();
        } else {
            if (anuncio.isMonetizado) {
                anuncioClick({ anuncioId: anuncio.id, token: anuncio.token }).then((response) => {
                    checkMonetizado();
                    setReceivedPoints(response.payload.data.pontos)
                    setShowAvisoPontos(true);

                    const dadosLocalStorage = obterDados();

                    const newDadosLocalStorage = {
                        ...dadosLocalStorage,
                        perfil: {
                            ...dadosLocalStorage.perfil,
                            pontos: session.perfil.pontos + response.payload.data.pontos
                        }
                    };

                    gravarDados(newDadosLocalStorage);

                    setSession({
                        ...session,
                        perfil: {
                            ...session.perfil,
                            pontos: session.perfil.pontos + response.payload.data.pontos,
                        }
                    })
                });
            }
            
            window.open(anuncio.link, "_blank");
        }
    }

    const handleVideoEnded = (e) => {
        console.log(e)
        if(e != undefined) {
            setReceivedPoints(e.pontos);
            setShowAvisoPontos(true);
            
            const dadosLocalStorage = obterDados();

            const newDadosLocalStorage = {
                ...dadosLocalStorage,
                perfil: {
                    ...dadosLocalStorage.perfil,
                    pontos: session.perfil.pontos + e.pontos
                }
            };

            gravarDados(newDadosLocalStorage);

            setSession({
                ...session,
                perfil: {
                    ...session.perfil,
                    pontos: session.perfil.pontos + e.pontos,
                }
            })
        }
        checkMonetizado()
    }

    function handleFecharAvisoPontosClick() {
        setShowAvisoPontos(false);
    }
    
    return (
        <>
            {showAvisoPontos && 
            <Popup onClose={handleFecharAvisoPontosClick}>
                <div className="popup-pontos-recebidos">
                    <img src={verified} alt="" />
                    <div className='popup-pontos-recebidos-titulo'>Parabéns!</div>
                    <div className='popup-pontos-recebidos-mensagem'>Você ganhou {receivedPoints} pontos.</div>
                    <BotaoArredondado value="OK" className='popup-pontos-recebidos-custom-button' onClick={handleFecharAvisoPontosClick} />
                </div>

            </Popup>}
            <VideoPopup url={anuncio.url} ref={videoPopupRef} onVideoEnded={handleVideoEnded} />
            <div className={`anuncio ${className} ${anuncio.isMonetizado ? 'anuncio-monetizado' : ''}`} {...rest} onClick={handleClick}>
                {anuncio.isMonetizado &&
                    <div className="barra-titulo">
                        <img className="imagem-barra-titulo" src={moneyOn} alt='money-on' />
                        <div className="barra-titulo-label">Clique e ganhe pontos</div>
                    </div>
                }
                <img className="imagemCapaCustom" src={anuncio.imagemCapa} alt={anuncio.texto} title={anuncio.texto} />
            </div>
        </>
    );
}