import React from 'react';
import './style.scss';


export default function BotaoRetanguloArredondado({ className, onClick, value, disabled = false, icon = '', ...rest }) {
    const handleClick = (e) => {
        if (!disabled && onClick !== undefined) onClick(e);
    }
    return (
        <button className={`${className} botao-retangulo-arredondado ${disabled ? 'botao-retangulo-desabilitado' : ''}`} onClick={handleClick} {...rest}>
            {icon !== '' && icon}
            <div className="text">{value}</div>
        </button>
    );
}