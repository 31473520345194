import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { obterAnuncios } from '../../actions/assistamais/api';
import { discoverMovie } from '../../actions/tmdb';
import fundo from '../../assets/img/pexels-august-de-richelieu-4260642.png';
import ListaTitulosGrid from '../../componentes/templates/ListaTitulosGrid';
import { SessionContext } from '../../contexts/SessionContext';
import { popularTipo } from '../../utils/titulos';
import './style.scss';

export default function TituloPorPlataformaGenero({ location }) {

    const [titulos, setTitulos] = useState([]);
    const [generoSelecionado, setGeneroSelecionado] = useState("");
    const [plataformaSelecionada, setPlataformaSelecionada] = useState("");

    const [anuncios, setAnuncios] = useState([]);

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    const popularGeneros = (results, genres) => {

        for (let r of results) {

            r.genres = [];
            r.genre_ids && r.genre_ids.forEach(id => {
                r.genres.push(genres.find(g => g.id === id))
            });
        }
    }

    const popularLinkDetalhe = (results, mediaType) => {

        for (let r of results) {
            let pathname = '/filme';
            if (mediaType.toLowerCase() === 'tv') {
                pathname = 'serie';
            }
            r.linkDetalhe = `${pathname}/?id=${r.id}&type=${mediaType}`;
        }
    }

    const carregarAnuncios = () => {
        try {
            obterAnuncios().then(result => {

                setAnuncios(result.payload.data.results[0].linha);
            });

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        const params = queryString.parse(location.search);
        const genero = systemData.genres.filter(g => g.id == params.genero);

        if (genero && genero.length > 0) {
            setGeneroSelecionado(genero[0].name);
        }

        const plataforma = systemData.providers.filter(p => p.provider_id == params.plataforma);
        if (plataforma && plataforma.length > 0) {
            setPlataformaSelecionada(plataforma[0].provider_name);
        }
    }, [location, systemData])


    useEffect(() => {
        /*
        plataforma
        genero
         */
        const params = queryString.parse(location.search);

        window.scrollTo(0, 0);

        carregarAnuncios()
        async function fetchData() {



            discoverMovie(`&with_watch_providers=${params.plataforma}&with_genres=${params.genero}|&watch_region=BR&`).then(result => {

                let { results } = result.payload.data;

                popularGeneros(results, systemData.genres);
                popularLinkDetalhe(results, 'movie');
                popularTipo(results, 'movie');
                const pagina1 = results;

                discoverMovie(`&with_watch_providers=${params.plataforma}&with_genres=${params.genero}|&watch_region=BR&`, 2).then(result => {

                    let { results = [] } = result.payload.data;

                    popularGeneros(results, systemData.genres);
                    popularLinkDetalhe(results, 'movie');
                    popularTipo(results, 'movie');
                    setTitulos([...pagina1, ...results])
                });
            });

        }
        fetchData()

    }, [location]);


    return <ListaTitulosGrid
        titulos={titulos}
        tituloPagina={generoSelecionado}
        fundo={fundo}
        subtituloPagina=""
        destaqueSubtitulo={`em ${plataformaSelecionada}`}
        onCheckMonetizado={() => carregarAnuncios()}
        anuncios={anuncios}
    />



}


TituloPorPlataformaGenero.propTypes = {

};

