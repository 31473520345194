import { useContext, useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from 'react-router-dom';
import { apagarClienteProvider, gravarClienteProvider, obterClienteProviders } from '../../../actions/assistamais/clientesProviders';
import { MessageContext } from '../../../contexts/MessageContext';
import { SessionContext } from '../../../contexts/SessionContext';
import { doLogin } from '../../../utils/Session';
import CardProvider from '../../atoms/CardProvider';
import './style.scss';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1367 },
        items: 15,
        slidesToSlide: 7,

    },
    desktop: {
        breakpoint: { max: 1366, min: 1024 },
        items: 10,
        slidesToSlide: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 10,
        slidesToSlide: 5
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 4
    }
};

const ListaProvidersHorizontal = ({ providers, titulo, onClick, onClickVerPlataforma }) => {

    const [listaProviders, setListaProviders] = useState([]);
    const [modoEdicao, setModoEdicao] = useState(false);

    useEffect(() => {
        updateLista();
    }, [providers]);

    const [session, setSession, logado] = useContext(SessionContext);
    const [showMessage, showError, showMessageFromResult] = useContext(MessageContext);

    const updateLista = () => {
        if (logado) {


            obterClienteProviders().then(resultado => {

                try {
                    const { data = [] } = resultado.payload;
                    const providersFiltered = providers.filter(p => data.some(d => d.providerId == p.provider_id))
                    //setListaProviders(providersFiltered);
                    setListaProviders(providers);
                } catch (err) {
                    console.error(err);
                    setListaProviders(providers);
                }

            })
        } else {
            setListaProviders(providers);
        }
    }

    let history = useHistory();

    const handleClickPlataforma = (id, name) => {
        history.push({
            pathname: '/titulosPorPlataforma/',
            search: `?id=${id}&name=${name}`
        }, {
            some: new Date()
        });

    }

    const handleClickAdicionarRemover = (id, remover = false) => {

        if (!remover) {
            showMessageFromResult(gravarClienteProvider(id), "Adicionado com sucesso.", "Não foi possível adicionar a plataforma, tente novamente mais tarde.")
        }
        else {
            showMessageFromResult(apagarClienteProvider(id), "Removido com sucesso.");
        }

        updateLista();
    }

    const handleClickAdicionarMais = () => {
        if (logado) {
            setModoEdicao(!modoEdicao)
        } else {
            doLogin();
        }
    }

    return (
        <div className="container-lista-provider-horizontal">
            <h1 className="Text-Style-2">{titulo}</h1>
            {!modoEdicao &&
                <Carousel responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    infinite={true}
                    partialVisible={true}
                    // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                //deviceType={this.props.deviceType||''}
                >
                    {listaProviders && listaProviders.length > 0 && listaProviders.map((p) => {
                        return <CardProvider className="card-provider-customizado" onClick={() => handleClickPlataforma(p.provider_id, p.provider_name)} logoPath={p.logo_path} name={p.provider_name} key={p.provider_id} />
                    }
                    )}

                </Carousel>
            }
            {modoEdicao &&
                <div className="lista-cards-providers">
                    {providers.map((p) => {
                        const existe = listaProviders.some(p2 => p2.provider_id === p.provider_id)
                        return <CardProvider className={`card-provider-customizado ${existe ? '' : 'card-provider-customizado-inativo'}`} onClick={() => handleClickAdicionarRemover(p.provider_id, existe)} logoPath={p.logo_path} name={p.provider_name} key={p.provider_id} />
                    }
                    )}
                </div>
            }

            {/* <div className="link-adicionar-mais-plataformas">
                {!modoEdicao && <BotaoRetanguloArredondadoFit value="+ADICIONAR MAIS PLATAFORMAS" onClick={handleClickAdicionarMais} />}
                {modoEdicao && <BotaoRetanguloArredondadoFit value="FECHAR" onClick={() => setModoEdicao(!modoEdicao)} />}
            </div> */}

        </div>
    )
};

export default ListaProvidersHorizontal;