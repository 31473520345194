
import ThumbQuadrada from '../../atoms/ThumbQuadrada';
import './style.scss';

const CardPost = ({titulo, descricao, className=''}) => {

    return (
        <>
            <div className={`card-post-container ${className}`}>
                <ThumbQuadrada className="card-post-thumb"/>
                <div className="card-post-dados">

                    <div className="card-post-titulo">{titulo}</div>
                    <div className="card-post-descricao">{descricao}aaaaaaaaa aaaaaaaaaaaaaaaaaaaaa</div>
                    <div className="card-post-link">
                        <a  href="#111" alt='ver mais'>Ver mais</a>
                    </div>
                </div>
                
            </div>
            <div className="card-post-divisor"></div>

        </>
    )
};

export default CardPost;