import React from 'react';

import './style.scss';

export default function ImagemDestaquePost({ className='',img, ...rest }) {

    return (
        <div className={`imagem-destaque-post ${className}`} {...rest}>
            <img src={img} alt='Imagem destaque post' />
        </div>
    );
}