import React from 'react';
import './style.scss';


export default function BotaoBordaArredondado({ className, onClick, value, children, ...rest }) {

    return (
        <div className={`botao-borda-arredondado ${className}`} onClick={onClick}>

            <div className="botao-borda-arredondado-text">{value}</div>
            <div className="transparencia"></div>
            {children}
        </div>
    );
}