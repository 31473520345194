import { useEffect, useState } from 'react';
import "react-multi-carousel/lib/styles.css";
import ItemResultadoBusca from '../../molecules/ItemResultadoBusca';
import './style.css';



const ListaResultadoFilmesBusca = ({ filmes, onClick, bannerGoogleAd= false }) => {


    const [listaFilmes, setListaFilmes] = useState([]);
    useEffect(() => {
        setListaFilmes(filmes.map(f => {
            f.other = {
                original_title: f.original_title,
                vote_average: f.vote_average
            }
            return f;
        }))

    }, [filmes]);

    return (
        <div className="container-lista-resultado-filmes-busca">
            {listaFilmes.map((f, idx) =>
                <ItemResultadoBusca
                    key={`item-resultado${f.id}`}
                    posterPath={f.poster_path}
                    name={f.title || f.name}
                    other={f.other}
                    onClick={() => onClick(f)}
                    providers={f.providers}
                    genres={f.genres}
                    numeroNaLista={idx + 1}
                    releaseDate={f.release_date || f.first_air_date}
                    type={f.media_type}
                />
            )}
        </div>
    )
};

export default ListaResultadoFilmesBusca;