import closeModal from '../../../assets/img/close-modal.png';
import InputBuscaMobile from '../../atoms/InputBuscaMobile';
import './style.css';
const BuscaMobile = ({ className = '', placeholder, onSubmit, onClose }) => {

    const handleCloseClick = (e) => {
        if (onClose) onClose(e);
    }

    return (
        <div className={`busca-mobile ${className}`}>
            <img src={closeModal} alt='close' className='busca-mobile-close-modal' onClick={handleCloseClick} />
            <InputBuscaMobile placeholder={placeholder} onSubmit={onSubmit} />
        </div>
    )
};

export default BuscaMobile;