import React from 'react';
import Anuncio from '../Anuncio';
import './style.scss';


export default function AnuncioMeiaTira({ anuncio, className = '', children, ...rest }) {

    return (
        <Anuncio anuncio={anuncio} className={`anuncio-meia-tira ${className}`} {...rest}>
            {children}
        </Anuncio>
    );
}