
import Noticia from '../../atoms/Noticia';
import './style.scss';

const ListaNoticiasVertical = ({ onClick, onClickVerPlataforma, items = [], classe, bannerGoogleAd = false}) => {
    return (
        <div className="container-lista-noticia-vertical">
            <div className="lista-noticias">
                {items.map(i =>
                    <Noticia classe={classe} onLeiaMaisClick={() => onClick(i.id)}
                        dataPostagem=""
                        tituloNoticia={i.name}
                        resumoNoticia={i.descricao}
                        img={i.src}
                    />
                )}

            </div>
        </div>
    )
};

export default ListaNoticiasVertical;