import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import assistaMais from '../../../assets/img/logo-rodape-assista.png';
import useMais from '../../../assets/img/usemaisnova.png';
import { getLinkUseMais } from '../../../actions/assistamais/api';
import './style.scss';


const ColunaRodapePoliticas = ({className = '',classNameImg ='',showText,classNameBody=''}) => {
    const handleClickUseMais = async () => {
        const linkData = await getLinkUseMais();
        window.open(linkData.payload.url,'_blank');
    }
    return (
        
        <div className={`links ${className}`}>
            <a href="/pdfs/politica-de-privacidade-assista-mais.html" target="_blank">POLÍTICA DE PRIVACIDADE</a>
            <a href="/pdfs/politica-de-pontos-assista-mais.pdf" target="_blank">POLÍTICA DE PONTOS</a>
            <a href="/pdfs/termo-de-uso-assista-mais.pdf" target="_blank">TERMOS DE USO</a>
        </div>
            
    )
};

export default ColunaRodapePoliticas;