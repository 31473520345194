import InputBuscaLocal from '../../atoms/InputBuscaLocal';
import './style.scss';

const BuscaLocal = ({ placeholder, onSubmit }) => {

    return (
        <div className="busca-local">
            <InputBuscaLocal placeholder={placeholder} onSubmit={onSubmit} />
        </div>
    )
};

export default BuscaLocal;