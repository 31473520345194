import React, { useEffect, useImperativeHandle, useState } from 'react';
import CardPopupInfo from '../../atoms/CardPopupInfo';
import CardPopupSelecaoProvider from '../../atoms/CardPopupSelecaoProvider';
import './style.scss';


const CardPopup = (props, ref) => {

    const [show, setShow] = useState(false);
    const [posicao, setPosicao] = useState([0, 0]);
    const [dados, setDados] = useState({});

    useImperativeHandle(ref, () => ({
        setData: (data) => setDados(data),
        setPosition: (x, y) => setPosicao([x - 10, y - 10]),
        toggle: () => {
            return setShow(!show);
        }
    }));

    const toggle = () => setShow(!show);

    const { poster_path, title, original_name, linkDetalhe, backdrop_path: backdropPath, overview, genres, tituloId, tipo } = dados;

    useEffect(() => {

        /* if (id) {

            getMovieWatchProviders(id).then(result => {
                const { data } = result.payload

            });
        } */

    }, [dados]);

    return !show ? <></> : (
        <div className="card-popup-box" style={{ left: posicao[0], top: posicao[1] }} onMouseLeave={toggle} >
            <CardPopupInfo posterPath={poster_path} title={title} linkDetalhe={linkDetalhe} backdropPath={backdropPath} overview={overview} genres={genres} id={tituloId} tipo={tipo} filme={dados} />
            <CardPopupSelecaoProvider providers={[]} />
        </div>
    );
}


export default React.forwardRef(CardPopup);