import React, { useState } from 'react';
import './style.scss';

export default function FaqItem({ titulo, children, resposta }) {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="faq-item">
            <div className="faq-item-titulo" onClick={() => setIsOpen(!isOpen)}>
                {titulo}
                <div className="faq-click" > {isOpen ? '-' : '+'} </div>
            </div>
            {isOpen &&
                <div className="faq-item-resposta" dangerouslySetInnerHTML={{ __html: resposta }}>


                </div>
            }
        </div>

    );
}