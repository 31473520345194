import moment from 'moment';
import config from '../config/default';
import apiRequest from '../utils/ApiRequest';

export const getPopularAction = async (splitSeries = false) => {
    let resultado: any;
    let resultadoSeries: any;

    await apiRequest().get(`/3/movie/popular?api_key=${config.apiKey}&language=pt-BR&page=1&region=BR&append_to_response=videos`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    await apiRequest().get(`/3/tv/popular?api_key=${config.apiKey}&language=pt-BR&page=1&region=BR&append_to_response=videos`).then((response: any) => {
        resultadoSeries = response;
    }).catch((error: any) => {
        resultadoSeries = error.response;
    });

    // adiciona o tipo a lista
    // limpa os dados que não tem conteúdo
    resultado.data.results = resultado.data.results.map( (v: Object) => ({ ...v, tipo: 'movie'})).filter((r: any) => r.overview !== '')
    resultadoSeries.data.results = resultadoSeries.data.results.map( (v: Object) => ({ ...v, tipo: 'tv'})).filter((r: any) => r.overview !== '')

    let retorno: any;
    retorno = { payload: resultado };
    if(splitSeries) {
        retorno = { ...retorno, payloadSeries: resultadoSeries };
    } else {
        let auxRetorno = [];
        for( let i = 0; i < 20; i++ ) {
            if(resultado.data.results[i]) auxRetorno.push(resultado.data.results[i]);
            if(resultadoSeries.data.results[i]) auxRetorno.push(resultadoSeries.data.results[i]);
        }
        resultado.data.results = auxRetorno;
        retorno = { payload: resultado };
    }
    return retorno;
}


export const getPlayingNowAction = async (splitSeries = false) => {
    let resultado: any;
    let resultadoSeries: any;

    await apiRequest().get(`/3/movie/now_playing?api_key=${config.apiKey}&language=pt-BR&region=BR&page=1`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    const lastAirDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    await apiRequest().get(`/3/discover/tv?api_key=${config.apiKey}&language=pt-BR&page=1&watch_region=BR&include_adult=false&sort_by=popularity.desc&air_date.gte=${lastAirDate}`).then((response: any) => {
        resultadoSeries = response;
    }).catch((error: any) => {
        resultadoSeries = error.response;
    });

    // adiciona o tipo a lista
    // limpa os dados que não tem conteúdo
    resultado.data.results = resultado.data.results.map( (v: Object) => ({ ...v, tipo: 'movie'})).filter((r: any) => r.overview !== '')
    resultadoSeries.data.results = resultadoSeries.data.results.map( (v: Object) => ({ ...v, tipo: 'tv'})).filter((r: any) => r.overview !== '')

    let retorno: any;
    retorno = { payload: resultado };
    if(splitSeries) {
        retorno = { ...retorno, payloadSeries: resultadoSeries };
    } else {
        let auxRetorno = [];
        for( let i = 0; i < 20; i++ ) {
            if(resultado.data.results[i]) auxRetorno.push(resultado.data.results[i]);
            if(resultadoSeries.data.results[i]) auxRetorno.push(resultadoSeries.data.results[i]);
        }
        resultado.data.results = auxRetorno;
        retorno = { payload: resultado };
    }
    return retorno;
}

export const getEmAltaNowAction = async (splitSeries = false) => {
    let resultado: any;
    let resultadoSeries: any;

    // await apiRequest().get(`/3/movie/now_playing?api_key=${config.apiKey}&language=pt-BR&page=1`).then((response: any) => {
    await apiRequest().get(`/3/trending/movie/week?api_key=${config.apiKey}&language=pt-BR&page=1`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    // await apiRequest().get(`/3/tv/now_playing?api_key=${config.apiKey}&language=pt-BR&page=1`).then((response: any) => {
    await apiRequest().get(`/3/trending/tv/week?api_key=${config.apiKey}&language=pt-BR&page=1`).then((response: any) => {
        resultadoSeries = response;
    }).catch((error: any) => {
        resultadoSeries = error.response;
    });

    // adiciona o tipo a lista
    // limpa os dados que não tem conteúdo
    resultado.data.results = resultado.data.results.map( (v: Object) => ({ ...v, tipo: 'movie'})).filter((r: any) => r.overview !== '')
    resultadoSeries.data.results = resultadoSeries.data.results.map( (v: Object) => ({ ...v, tipo: 'tv'})).filter((r: any) => r.overview !== '')

    let retorno: any;
    retorno = { payload: resultado };
    if(splitSeries) {
        retorno = { ...retorno, payloadSeries: resultadoSeries };
    } else {
        let auxRetorno = [];
        for( let i = 0; i < 20; i++ ) {
            if(resultado.data.results[i]) auxRetorno.push(resultado.data.results[i]);
            if(resultadoSeries.data.results[i]) auxRetorno.push(resultadoSeries.data.results[i]);
        }
        resultado.data.results = auxRetorno;
        retorno = { payload: resultado };
    }
    return retorno;
}

export const getMovieWatchProviders = async (movieId: number) => {
    let resultado;

    //    https://api.themoviedb.org/3/movie/{movie_id}/watch/providers?api_key=<<api_key>>
    await apiRequest().get(`/3/movie/${movieId}/watch/providers?api_key=${config.apiKey}&language=pt-BR&region=BR`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

export const getGenresMovieList = async (movieId: number) => {
    let resultado: any;
    let resultadoSeries: any;

    await apiRequest().get(`/3/genre/movie/list?api_key=${config.apiKey}&language=pt-BR`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    await apiRequest().get(`/3/genre/tv/list?api_key=${config.apiKey}&language=pt-BR`).then((response: any) => {
        resultadoSeries = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    let retorno: any;
    retorno = { payload: resultado };

    let auxRetorno = [];
    for( let i = 0; i < 20; i++ ) {
        if(resultado.data.genres[i]) auxRetorno.push(resultado.data.genres[i]);
        // eslint-disable-next-line no-loop-func        
        if(resultadoSeries.data.genres[i] && !resultado.data.genres.find((e: any) => e.id === resultadoSeries.data.genres[i].id || e.name === resultadoSeries.data.genres[i].name) ) auxRetorno.push(resultadoSeries.data.genres[i]);
    }
    resultado.data.genres = auxRetorno;
    retorno = { payload: resultado };
    return retorno;
}

export const getMovieDetails = async (movieId: number) => {
    let resultado;

    await apiRequest().get(`/3/movie/${movieId}?api_key=${config.apiKey}&language=pt-BR&append_to_response=credits,release_dates,videos,images`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

export const getMovieCredits = async (movieId: number) => {
    let resultado;

    await apiRequest().get(`/3/movie/${movieId}/credits?api_key=${config.apiKey}&language=pt-BR`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}


export const getMovieImages = async (movieId: number) => {
    let resultado;

    await apiRequest().get(`/3/movie/${movieId}/images?api_key=${config.apiKey}&include_image_language=en,br`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

export const getMovieRecommendations = async (movieId: number) => {
    let resultado;

    await apiRequest().get(`/3/movie/${movieId}/recommendations?api_key=${config.apiKey}&language=pt`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

export const getMovieSimilar = async (movieId: number) => {
    let resultado;

    await apiRequest().get(`/3/movie/${movieId}/similar?api_key=${config.apiKey}&language=pt`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

export const getMovieVideos = async (movieId: number) => {
    let resultado;

    await apiRequest().get(`/3/movie/${movieId}/videos?api_key=${config.apiKey}&language=pt-BR&region=BR`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

// SEARCHs 

export const searchMovieAction = async (query: string, page: number = 1) => {
    let resultado;

    await apiRequest().get(`/3/search/movie?api_key=${config.apiKey}&language=pt-BR&page=${page}&query=${query}`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

//https://api.themoviedb.org/3/search/person?api_key=<<api_key>>&language=en-US&page=1&include_adult=false

export const searchPerson = async (query: string, page: number = 1) => {
    let resultado;

    await apiRequest().get(`/3/search/person?api_key=${config.apiKey}&language=pt-BR&page=${page}&query=${query}`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

export const searchMulti = async (query: string, page: number = 1) => {
    let resultado;

    await apiRequest().get(`/3/search/multi?api_key=${config.apiKey}&sort_by=release_date.desc&language=pt-BR&page=${page}&query=${query}&append_to_response=release_dates`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

// https://api.themoviedb.org/3/search/multi?api_key=<<api_key>>&language=en-US&page=1&include_adult=false

// Discover

// https://api.themoviedb.org/3/discover/movie?api_key=<<api_key>>&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_cast=scarlett&with_watch_monetization_types=flatrate

export const discoverMovie = async (query: string, page: number = 1, splitSeries = false) => {
    let resultado: any;
    let resultadoSeries: any;
    // removido da url &with_watch_monetization_types=free    
    await apiRequest().get(`/3/discover/movie?api_key=${config.apiKey}&language=pt-BR${query}&page=${page}&include_adult=false`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    await apiRequest().get(`/3/discover/tv?api_key=${config.apiKey}&language=pt-BR${query}&page=${page}&include_adult=false`).then((response: any) => {
        resultadoSeries = response;
    }).catch((error: any) => {
        resultadoSeries = error.response;
    });

    // adiciona o tipo a lista
    // limpa os dados que não tem conteúdo
    resultado.data.results = resultado.data.results.map( (v: Object) => ({ ...v, tipo: 'movie'})).filter((r: any) => r.overview !== '')
    resultadoSeries.data.results = resultadoSeries.data.results.map( (v: Object) => ({ ...v, tipo: 'tv'})).filter((r: any) => r.overview !== '')

    let retorno: any;
    retorno = { payload: resultado };
    if(splitSeries) {
        retorno = { ...retorno, payloadSeries: resultadoSeries };
    } else {
        let auxRetorno = [];
        for( let i = 0; i < 20; i++ ) {
            if(resultado.data.results[i]) auxRetorno.push(resultado.data.results[i]);
            if(resultadoSeries.data.results[i]) auxRetorno.push(resultadoSeries.data.results[i]);
        }
        resultado.data.results = auxRetorno;
        retorno = { payload: resultado };
    }
    return retorno;
}


// -------------------------- TV

export const getTVDetails = async (tvId: number) => {
    let resultado: any;
    await apiRequest().get(`/3/tv/${tvId}?api_key=${config.apiKey}&language=pt-BR&watch_region=BR&region=BR&append_to_response=credits,content_ratings,videos,images`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

export const getTVCredits = async (tvId: number) => {
    let resultado;

    await apiRequest().get(`/3/tv/${tvId}/credits?api_key=${config.apiKey}&language=pt-BR,en`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}


export const getTVImages = async (tvId: number) => {
    let resultado;

    await apiRequest().get(`/3/tv/${tvId}/images?api_key=${config.apiKey}&language=pt-BR,pt,en,null`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

export const getTVSimilar = async (tvId: number) => {
    let resultado;

    await apiRequest().get(`/3/tv/${tvId}/similar?api_key=${config.apiKey}&language=pt-BR,pt`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

export const getTVWatchProviders = async (tvId: number) => {
    let resultado;

    //    https://api.themoviedb.org/3/movie/{movie_id}/watch/providers?api_key=<<api_key>>
    await apiRequest().get(`/3/tv/${tvId}/watch/providers?api_key=${config.apiKey}&language=pt-BR&region=BR`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

export const getTVClassificacaoEtaria = async (tvId: number) => {
    let resultado;

    //    https://api.themoviedb.org/3/movie/{movie_id}/watch/providers?api_key=<<api_key>>
    await apiRequest().get(`/3/tv/${tvId}/content_ratings?api_key=${config.apiKey}&language=pt-BR&region=BR`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}


// Get Watch providers

export const getWatchProvidersMovie = async () => {
    let resultado;

    await apiRequest().get(`/3/watch/providers/movie?api_key=${config.apiKey}&language=pt-BR&watch_region=BR`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}


// Genres

export const getGenreMovieList = async () => {
    let resultado;
    // https://api.themoviedb.org/3/genre/movie/list?
    await apiRequest().get(`/3/genre/movie/list?api_key=${config.apiKey}&language=pt-BR`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

// Videos

