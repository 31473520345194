import React from 'react';
import './style.scss';


export default function BotaoArredondado({ className = '', value, ativo = true, disabledTitle = '', disabledValue = '', ...rest }) {

    return (
        <button className={`botao-arredondado ${className} ${ativo ? '' : 'botao-inativo'}`} {...rest} disabled={!ativo} title={!ativo ? disabledTitle : ''}>
            <div className="text">{!ativo && disabledValue ? disabledValue : value}</div>
        </button>
    );
}