import React from 'react';
import './style.scss';


export default function InputCheck({ className = '', label, name, error, children, ...rest }) {

    return (
        <div className={`input-check ${className}`}>
            <div className='input-and-label'>
                <input type="checkbox" {...rest} />
                {children}
            </div>

            {error && error[name] ? <span className="input-error">{error[name]}</span> : label && <span className="input-label">{label}</span>}
        </div>
    );
}