import React, { useContext, useEffect } from 'react';
import bigUser from '../../assets/img/big-user.png';
import imagemFaq from '../../assets/img/image-faq.png';
import assistaMais from '../../assets/img/logo-rodape-assista.png';
import moneyMarket from '../../assets/img/money-market.png';
import streamScreen from '../../assets/img/stream-screen.png';
import ImagemFundoDetalhe from '../../componentes/organisms/ImagemFundoDetalhe';
import Menu from '../../componentes/organisms/Menu';
import Rodape from '../../componentes/organisms/Rodape';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import { SessionContext } from '../../contexts/SessionContext';
import './style.scss';

export default function Anuncie() {

    const [, , , , getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    return (
        <div>
            <Menu simples={true} pesquisa={false} />
            <ImagemFundoDetalhe img={imagemFaq} className="anuncie-fundo" >
                <div className="anuncie-cabecalho">
                    <img src={assistaMais} alt='' />

                    <div className='anuncie-titulo'>Uma Plataforma Guia de Entretenimento</div>
                </div>
            </ImagemFundoDetalhe>

            <div className="anuncie-body">

                <div className="anuncie-conteudo">

                    <div className='anuncie-conteudo-item'>
                        <img src={bigUser} alt='' />
                        <span>Somos uma plataforma guia de entretenimento que facilita a jornada do usuário na busca por filmes e séries, e oferece resgate de prêmios através do <a href="/comofunciona">Programa Use Mais Pontos.</a></span>
                    </div>

                    <div className='anuncie-conteudo-item'>
                        <img src={moneyMarket} alt='' />
                        <span>Ofereça seu produto/serviço, enquanto seu futuro cliente busca entretenimento.
                        </span>
                    </div>

                    <div className='anuncie-conteudo-item'>
                        <img src={streamScreen} alt='' />
                        <span>Conheça nossas opções de mídia (espaços personalizados de vídeos e banners) e os pacotes de pontos para bonificar e fidelizar os seus clientes.
                        </span>
                    </div>

                </div>

                <div className="anuncie-com-a-gente">
                    <span>ANUNCIE COM A GENTE!</span>
                    {/* <BotaoArredondado className='anuncie-botao-custom' value="Começar agora mesmo" /> */}
                    <a className='anuncie-email' href="mailto:comercial@assistamais.net.br">
                        comercial@assistamais.net.br
                    </a>
                </div>

            </div>
            <RodapeConta />
        </div>

    );
}


Anuncie.propTypes = {

};

