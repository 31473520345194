import React, { useRef } from 'react';
import CardPopup from '../../molecules/CardPopup';
import ListaFilmesHorizontal from '../ListaFilmesHorizontal';
import './style.scss';

const CamadaRelacionados = ({ filmes, handleRelacionadosClick }) => {

    const cardPopup = useRef(null);

    const handleMouseOver = (e, data, { top, left, width: cardWidth, height: cardHeight }) => {
        const { scrollY, scrollX } = window;

        const width = document.body.clientWidth;
        const multiplicador = width > 1366 ? 1 : 0.71;

        let x = scrollX + left + cardWidth / 2 - (432 * multiplicador) / 2;
        let y = scrollY + top + cardHeight / 2 - (481 * multiplicador) / 2;

        if (x < 0) x = 0;
        if (y < 0) y = 0;

        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    return (
        <div className="relacionados">
            <CardPopup ref={cardPopup} />
            <div className="relacionados-body">
                <div className="relacionados-titulo">Títulos semelhantes</div>
                <ListaFilmesHorizontal onClick={handleRelacionadosClick} filmes={filmes} titulo={""} cardPopup={cardPopup} onMouseOver={handleMouseOver} />
            </div>
        </div>

    )
};

export default CamadaRelacionados;