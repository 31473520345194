
import { useHistory } from 'react-router-dom';
import AnuncioTiraEmPe from '../../atoms/AnuncioTiraEmPe';
import './style.scss';

const AnuncioTiraEmPeComTitulo = ({ className, anuncio, onCheckMonetizado }) => {

    let history = useHistory();

    const handleClickAnuncio = (id, name) => {

    }
    
    return (
        <div className={`container-anuncio-tira-em-pe-titulo ${className}`}>
            <div>
                <div className="lista-anuncios-tira-em-pe-titulo">PUBLICIDADE</div>
                <div className="lista-anuncios">
                    <AnuncioTiraEmPe anuncio={{ ...anuncio, imagemCapa: anuncio.imagemCapa }} onCheckMonetizado={onCheckMonetizado} />
                </div>
            </div>
        </div>
    )
};

export default AnuncioTiraEmPeComTitulo;