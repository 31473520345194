import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { obterAnuncios, obterLista } from '../../actions/assistamais/api';
import fundo from '../../assets/img/pexels-august-de-richelieu-4260642.png';
import ListaTitulosGrid from '../../componentes/templates/ListaTitulosGrid';
import { SessionContext } from '../../contexts/SessionContext';
import './style.scss';

export default function ListaTitulosListaPersonalizada({ location }) {

    const [titulos, setTitulos] = useState([]);
    const [dadosDaLista, setDadosDaLista] = useState({});

    const [anuncios, setAnuncios] = useState([]);

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    const carregarAnuncios = () => {
        try {
            obterAnuncios('LISTAS').then(result => {
                setAnuncios(result.payload.data.results[0].linha);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        carregarAnuncios()
        async function fetchData() {
            const params = queryString.parse(location.search);

            obterLista(params.id).then(result => {
                if (Math.trunc(result.payload.status / 100) !== 2) return;

                let { data } = result.payload;

                setDadosDaLista({ name: data.results.name, descricao: data.results.descricao });
                if (data.results.titulos) {

                    setTitulos(data.results.titulos.map(f => {
                        let pathname = '/filme';
                        if (f.tipo.toLowerCase() === 'tv') {
                            pathname = 'serie';
                        }
                        const linkDetalhe = `${pathname}/?id=${f.tituloId}&type=${f.tipo}`;
                        const { genres, backdrop_path, poster_path, title, overview } = f.data

                        return {
                            ...f,
                            genres,
                            backdrop_path,
                            poster_path,
                            title,
                            overview,
                            linkDetalhe: linkDetalhe
                        }
                    }));
                }

            });

        }
        fetchData()

    }, []);

    return <ListaTitulosGrid
        fundo={fundo}
        titulos={titulos}
        subtituloPagina={dadosDaLista.descricao}
        tituloPagina={dadosDaLista.name}
        anuncios={anuncios}
        onCheckMonetizado={() => carregarAnuncios()}
    />
}


ListaTitulosListaPersonalizada.propTypes = {

};

