import { apiAssistaMaisRequest } from '../../utils/ApiRequest';

export const gravarClienteProvider = async (providerId: Number) => {

    let resultado;

    await apiAssistaMaisRequest().post(`/clientes/providers`, { providerId }).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;

    });

    return ({
        payload: resultado
    });
}

export const apagarClienteProvider = async (providerId: Number) => {

    let resultado;

    await apiAssistaMaisRequest().delete(`/clientes/providers/${providerId}`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}
export const obterClienteProviders = async () => {

    let resultado;

    await apiAssistaMaisRequest().get(`/clientes/providers/`).then((response: any) => {
        resultado = response;
    }).catch((error: any) => {
        resultado = error.response;
    });

    return ({
        payload: resultado
    });
}

