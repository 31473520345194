import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { MessageContext } from '../../../contexts/MessageContext';
import { SessionContext } from '../../../contexts/SessionContext';
import './style.scss';


export default function InputBuscaMobile({ placeholder, onSubmit, }) {
    const [valor, setValor] = useState('');
    const [provider, setProvider] = useState(0);
    const [genrer, setGenrer] = useState(0);
    const [, , , systemData] = useContext(SessionContext);
    const [showMessage, showError] = useContext(MessageContext);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = () => {
        if (valor.length === 0) {
            showError("Digite um texto para realizar a busca");
            return
        }
        onSubmit(valor, { provider, genrer });
    }

    const handleTextChange = (e) => setValor(e.target.value);
    const handleProviderChange = (e) => setProvider(e.target.value);
    const handleGenrerChange = (e) => setGenrer(e.target.value);


    return (
        <div className="input-busca-mobile-container">

            <div className="input-busca-mobile" >
                <div className="input-busca-mobile-field">
                    <input type="text" name="busca" value={valor} placeholder={placeholder} onChange={handleTextChange} onKeyDown={handleKeyDown} />
                    <div className="botao-lupa" onClick={handleSubmit}><FontAwesomeIcon icon={faSearch} /></div>
                </div>
            </div>

            <div className="filtro-busca">
                <select onChange={handleProviderChange}>
                    <option value="0">Plataformas</option>
                    {systemData.providers.map(p =>
                        <option key={`provider-option-${p.provider_id}`} value={p.provider_id}>{p.provider_name}</option>
                    )}
                </select>

                <select onChange={handleGenrerChange}>

                    <option value="0">Gêneros</option>
                    {systemData.genres.map(g =>
                        <option key={`genrer-option-${g.id}`} value={g.id}>{g.name}</option>
                    )}


                </select>
            </div>

        </div>

    );
}