import React, { useContext, useEffect, useState } from 'react';
import { classificar, getFavorito, obterClassificao } from '../../../actions/assistamais/api';
import { MessageContext } from '../../../contexts/MessageContext';
import { SessionContext } from '../../../contexts/SessionContext';
import { doLogin } from '../../../utils/Session';
import Favoritar from '../../atoms/Favoritar';
import ShareButton from '../../atoms/ShareButton';
import ReactStarsCustom from '../../molecules/ReactStarsCustom';
import './style.scss';

const ClassificarCompartilhar = ({ id, menuRef, onClick, tipo, filme }) => {

    const [session, setSession, logado] = useContext(SessionContext);
    const [showMessage, showError, showMessageFromResult] = useContext(MessageContext);

    const [favorito, setFavorito] = useState(false);
    const [classificacao, setClassificacao] = useState(0);
    const [mostrarClassificacao, setMostrarClassificacao] = useState(true);

    // const handleFavoritarClick = async (e) => {

    //     if (!logado) {
    //         doLogin();
    //     } else {
    //         if (!favorito) {
    //             const { title, genres, overview, backdrop_path, poster_path, original_name } = filme;
    //             const data = {
    //                 title: title || original_name, genres, overview, backdrop_path, poster_path,
    //             };

    //             if (await showMessageFromResult(favoritar({ tituloId: id, tipo: tipo, data: data }), "Adicionado aos favoritos",)) {
    //                 setFavorito(true);
    //             }

    //         } else {
    //             if (await showMessageFromResult(desfavoritar({ tituloId: id, tipo: tipo }), "Removido da lista de favoritos",)) {
    //                 setFavorito(false);
    //             }
    //         }
    //     }
    // }

    const handleClassificarChange = (e) => {
        if (!logado) {
            doLogin();
        } else {
            // obterClassificao({ tituloId: id, tipo: tipo }).then(resultado => console.log("classificacao", resultado));
            classificar({ tituloId: id, tipo: tipo, nota: e });
            showMessage(`Classificado com ${e} estrela(s)`);
        }
    }

    useEffect(() => {
        if (logado) {
            getFavorito({ tituloId: id, tipo }).then(result => {
                if (result && result.payload && result.payload.data) {
                    setFavorito(result.payload.data.tituloId !== undefined);
                }
            });

            obterClassificao({ tituloId: id, tipo }).then(result => {
                if (result && result.payload && result.payload.data) {
                    setClassificacao(result.payload.data.nota);
                    // necessário para atualiza o objeto de classificação
                    resetClassificacao()
                }
            });

        }
    }, [id])

    const resetClassificacao = () => {
        setMostrarClassificacao(false);
        setMostrarClassificacao(true);
    }

    return (
        <div className="classificar-compartilhar">
            <h1>Classifique o título</h1>
            <div className="classificar-compartilhar-opcoes">
                <div className="rating">

                    {mostrarClassificacao && <ReactStarsCustom value={classificacao} onChange={handleClassificarChange} />}

                </div>
                <Favoritar className="favoritar-custom" filme={filme} tipo={tipo} id={id} />
                <ShareButton className="share-custom" logado={logado} />
            </div>
        </div>
    )
};

export default ClassificarCompartilhar;