import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { obterAnuncios } from '../../actions/assistamais/api';
import fundo from '../../assets/img/pexels-johnmark-smith-2726370.png';
import Anuncio from '../../componentes/atoms/Anuncio';
import ImagemFundoDetalhe from '../../componentes/organisms/ImagemFundoDetalhe';
import Menu from '../../componentes/organisms/Menu';
import PostBody from '../../componentes/organisms/PostBody';
import PostsRelacionados from '../../componentes/organisms/PostsRelacionados';
import Rodape from '../../componentes/organisms/Rodape';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import { SessionContext } from '../../contexts/SessionContext';
import './style.scss';




export default function ConteudoPost({ location }) {

    const [titulo, setTitulo] = useState('');
    const [anuncios, setAnuncios] = useState([]);
    const [idAnuncio, setIdAnuncio] = useState(0);

    const [, , , , getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    useEffect(() => {
        const params = queryString.parse(location.search);
        async function fetchData() {

        };

        obterAnuncios('CONTEUDO-NOTICIA').then(result => {
            try {
                setAnuncios(result.payload.data.results[0].linha);

            } catch (err) {
                console.error(err);
            }
        });

        fetchData()

        // const interval = setInterval(() => {
        //     setIdAnuncio(idAnuncio + 1 === anuncios.length ? 0 : idAnuncio + 1);
        // }, 2000);

        window.scrollTo(0, 0);
        // return () => {
        //     clearInterval(interval);
        // };
    }, [location]);



    return (
        <div className="conteudo-post">
            <Menu simples={false} pesquisa={false} className="conteudo-post-menu" />
            <ImagemFundoDetalhe img={fundo} className="conteudo-post-fundo" opacity={false}>
                <div className="titulo-pagina">
                    <div className="titulo-principal">Lorem ispum dolor et mum essestis nim</div>

                </div>
            </ImagemFundoDetalhe>
            <div className="colunas">
                <PostBody />
                <PostsRelacionados anuncio={anuncios[idAnuncio]} />
            </div>
            <div className="mobile">
                <PostBody />
                <div className="card-post-publicidade">
                    <div className="card-post-publicidade-label">PUBLICIDADE</div>
                    {anuncios && <Anuncio anuncio={anuncios[idAnuncio]} />}
                </div>
                <PostsRelacionados />
            </div>

            <div className="conteudo-post-relacionados">

            </div>


            <RodapeConta />
        </div>
    );
}


ConteudoPost.propTypes = {

};

