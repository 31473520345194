import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { verificarEmail } from '../../actions/assistamais/api';
import PopupAviso from '../../componentes/organisms/PopupAviso';
import { MessageContext } from '../../contexts/MessageContext';
import Home from '../Home';
import './style.scss';

export default function ConfirmacaoEmail() {

    const [showMessage, showError, showMessageFromResult] = useContext(MessageContext);

    const [showAviso, setShowAviso] = useState(false);
    let history = useHistory();
    useEffect(() => {
        const params = queryString.parse(window.location.search);
        if (params.token) {

            showMessageFromResult(verificarEmail(params.token)).then(success => {
                if (success) {
                    setShowAviso(true);
                } else {
                    history.push({
                        pathname: '/'
                    });
                }

            })
        }

    }, []);

    const handleClose = () => {
        setShowAviso(false);
        history.push({
            pathname: '/'
        });
    }

    return (
        <div className="confirmacao-email">

            {showAviso && <PopupAviso titulo="E-mail confirmado com sucesso!" mensagem="Seu e-mail foi confirmado. Acesse sua conta e aproveite!" onClose={handleClose} />}
            <Home />

        </div>
    );
}



